import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/Unauthorized.css'; // Create your custom styling here if needed
import Header from '../components/header';
function Unauthorized() {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login'); // Redirect to login page
    };

    const handleHome = () => {
        navigate('/'); // Redirect to home page
    };

    return (
        <>
        <Header></Header>
        <div className="unauthorized-container">
            <h1>Unauthorized Access</h1>
            <p>You do not have the necessary permissions to view this page.</p>
            {/* <div className="unauthorized-buttons">
                <button onClick={handleHome}>Go to Homepage</button>
                <button onClick={handleLogin}>Login</button>
            </div> */}
        </div>
        </>
    );
}

export default Unauthorized;
