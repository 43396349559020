import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import { useNavigate } from 'react-router-dom';

export default function Update() {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);

    // Check for mobile screen size
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Dynamic styles for desktop and mobile
    const containerStyle = {
        maxWidth: isMobile ? '95%' : '800px', // 95% width on mobile, 800px on desktop
        margin: '20px auto', // Auto margin for centering
        padding: isMobile ? '10px' : '20px', // Smaller padding for mobile
        backgroundColor: '#fff', // Background color for the text container
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow around the container
        borderRadius: '8px', // Rounded corners for a modern look
    };

    const headerStyle = {
        textAlign: 'center',
        fontSize: isMobile ? '20px' : '24px', // Smaller font size for mobile
        marginBottom: '20px',
        color: '#0a4b39',
    };

    const sectionStyle = {
        margin: '20px 0',
    };

    const h4Style = {
        color: '#0a4b39',
        fontSize: isMobile ? '16px' : '18px', // Adjust font size for mobile
    };

    const linkStyle = {
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer',
    };

    return (
        <>
            <Header />
            <div style={containerStyle}>
                <h1 style={headerStyle}>Release Notes Announcements</h1>
                
                <main>
                    <section style={sectionStyle}>
                        <h3>September 6, 2024 Release</h3>
                        <p>
                            TimberwolveService has moved under a new system and server. Here's a summary of what's new and improved:
                        </p>
                    </section>

                    <section style={sectionStyle}>
                        <h4 style={h4Style}>1. The Login and Authentication System</h4>
                        <p>
                            All data on the old system was migrated to the new system effective September 5, 2024. This includes all accounts, volunteer entries, and
                            volunteer organizations. If you registered for an account before September 5, 2024, your account is now registered as an old account. When you go back to sign into your
                            account <span style={linkStyle} onClick={() => navigate('/about-verification')}>here</span>, simply
                            enter your email into the login and press submit. Whatever input that is inside the password field will be neglected if you registered for an account before this date.
                            Upon submission, a password reset link will be sent to your email. Once you complete the password reset, you will be able to login to your account with no problems.
                            All data that you submitted prior to the effective date will automatically appear on your account. If you are having login problems or missing any data for whatever reason, please email {" "}
                            <a href="mailto:TimberwolveService@gmail.com" style={{ color: '#0a4b39' }}>TimberwolveService@gmail.com</a> so that we can resolve them as soon as possible.
                        </p>
                    </section>

                    <section style={sectionStyle}>
                        <h4 style={h4Style}>2. Volunteer Entry Verification</h4>
                        <p>
                            This update has automated the verification of volunteer entries. Whenever creating a new entry in your portfolio, an automated email will be 
                            sent to the email specified by the contact field. The email will contain the entry information, along with a link that will redirect them 
                            to a page for the recepient to reject or accept the volunteer entry as valid. Only entries that have been verified will be sent to your organization's 
                            administration and considered on the TimberwolveService leaderboard. You can learn more about verification by {" "}
                            <span style={linkStyle} onClick={() => navigate('/about-verification')}>
                                clicking here
                            </span>.
                        </p>
                    </section>

                    <section style={sectionStyle}>
                        <h4 style={h4Style}>3. Implemented Update and Delete Feature in Volunteer Portfolio</h4>
                        <p>
                            All users can now update and delete their volunteer entries in their portfolio. Update of an entry will result in the entry being unverified and an automated
                            email being sent to the new contact specified in the entry.
                        </p>
                    </section>

                    <section style={sectionStyle}>
                        <h4 style={h4Style}>4. Released Community Events (Beta)</h4>
                        <p>
                            We have released a beta for a community events system, where any user on the platform will be able to post a volunteer event. Users on the system would then
                            be able to sign up for any posted volunteer event. Upon registration to an event, your account email will be automatically sent to the event organizer.
                            Note that this system is still in its early stages, and our team is still working to discover any way to improve the effectiveness and value of the system and
                            any bugs that may persist. You will be able to view all your community's events by {" "}
                            <span style={linkStyle} onClick={() => navigate('/event-list')}>
                                clicking here
                            </span>.
                        </p>
                    </section>

                    <section style={sectionStyle}>
                        <h4 style={h4Style}>5. Other Improved Features</h4>
                        <ul>
                            <li>Updated design and functionality of volunteer leaderboard</li>
                            <li>Updated design and functionality of volunteer portfolio</li>
                            <li>Changed overall scheme and images of the site</li>
                            <li>Changed overall scheme and images of the site</li>
                            <li>Removed all features non-related to community service such as Project Recall</li>
                        </ul>
                    </section>
                </main>
            </div>
        </>
    );
}
