import Header from '../../components/header';
import '../css/Portfolio.css';
import PortfolioDeleteButton from '../../components/portfolio_delete_button';
import PortfolioUpdateButton from '../../components/portfolio_update_button';
import '../css/checkmark.css';
import '../css/App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // useParams to get the user ID from URL
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import ReactPaginate from 'react-paginate'; // Import pagination
import '../css/Entries_Detail.css';
// AXIOS setup for security purposes
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Client Instance with Django URL in order to type the URL only once
const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function AdminEntryDetail() {
  const csrftoken = getCookie('csrftoken');
  const navigate = useNavigate();
  const { userId } = useParams();
  const [data, setData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [sortedField, setSortedField] = useState(null);
  const [sortingEventOrder, setSortingEventOrder] = useState('desc');
  const [sortingDateOrder, setSortingDateOrder] = useState('desc');
  const [sortingHoursOrder, setSortingHoursOrder] = useState('desc');
  const [sortingRoleOrder, setSortingRoleOrder] = useState('desc');
  const [sortingOrganizerOrder, setSortingOrganizerOrder] = useState('desc');
  const [searchEvent, setSearchEvent] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const [searchHours, setSearchHours] = useState('');
  const [searchRole, setSearchRole] = useState('');
  const [searchOrganizer, setSearchOrganizer] = useState('');
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const entriesPerPage = 20; // Limit entries to 20 per page

  // UseMediaQuery to detect mobile devices
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const inputStyle = {
    width: isMobile ? '100%' : '230px',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    margin: '5px 0',
    boxSizing: 'border-box',
  };

  const containerStyle = {
    overflowX: 'auto',
    marginBottom: '20px',
  };

  useEffect(() => {
    client.get(`/admin/user-entries/${userId}`, {
      headers: { 'X-CSRFToken': csrftoken }
    })
    .then(res => {
      setData(res.data.entries);
      setUserDetails(res.data.user);
    })
    .catch(err => console.error('Error fetching data:', err));
  }, [csrftoken, userId]);

  const clickSort = (field) => {
    setSortedField(field);
    switch (field) {
      case 'event':
        setSortingEventOrder(sortingEventOrder === 'asc' ? 'desc' : 'asc');
        break;
      case 'date':
        setSortingDateOrder(sortingDateOrder === 'asc' ? 'desc' : 'asc');
        break;
      case 'hours':
        setSortingHoursOrder(sortingHoursOrder === 'asc' ? 'desc' : 'asc');
        break;
      case 'role':
        setSortingRoleOrder(sortingRoleOrder === 'asc' ? 'desc' : 'asc');
        break;
      case 'organizer':
        setSortingOrganizerOrder(sortingOrganizerOrder === 'asc' ? 'desc' : 'asc');
        break;
      default:
        break;
    }
  };

  const handleVerifyToggle = (entryId) => {
    client.post(`/admin/entries/${entryId}/verify`, {}, {
      headers: { 'X-CSRFToken': csrftoken }
    })
    .then(() => {
      setData(data.map(entry => 
        entry.id === entryId ? { ...entry, verified: !entry.verified } : entry
      ));
    })
    .catch(err => console.error('Error toggling verification:', err));
  };

  const clickDelete = (pk) => {
    setData(data.filter(item => item.id !== pk));
    client.delete(`/admin/entries/${pk}/delete`, {
      headers: { 'X-CSRFToken': csrftoken }
    })
    .then(() => console.log('Deleted Event'))
    .catch(err => console.error('Error deleting event:', err));
  };

  const sortedData = data.sort((a, b) => {
    if (!sortedField) return 0;
    const order = sortedField === 'event' ? sortingEventOrder : sortedField === 'date' ? sortingDateOrder : sortedField === 'hours' ? sortingHoursOrder : sortedField === 'role' ? sortingRoleOrder : sortingOrganizerOrder;
    return order === 'asc' ? a[sortedField] < b[sortedField] ? -1 : 1 : a[sortedField] > b[sortedField] ? -1 : 1;
  });

  const paginatedData = sortedData.slice(currentPage * entriesPerPage, (currentPage + 1) * entriesPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const totalVerifiedHours = data.reduce((total, entry) => entry.verified ? total + entry.hours : total, 0);
  const verifiedEntriesCount = data.filter(entry => entry.verified).length;

  return (
    <div>
      <Header />
      <div className="center" style={{ fontFamily: "'Carme', sans-serif" }}>
        <h2>
            Volunteer Hours for {userDetails.first_name} {userDetails.last_name} ({userDetails.email})
        </h2>
    </div>
    <div className="center" style={{ fontFamily: "'Carme', sans-serif" }}>
        <p style={{ marginRight: '20px', marginLeft: '20px'}}>
        Total Verified Volunteer Hours: {totalVerifiedHours}
        </p>
        <p style={{ marginLeft: '20px', marginRight: '20px' }}>
        Number of Verified Entries: {verifiedEntriesCount}
        </p>
    </div>

    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', overflowX: 'auto', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
      <input
        type="text"
        placeholder="Search by Event"
        value={searchEvent}
        onChange={(e) => setSearchEvent(e.target.value)}
        style={inputStyle}
      />
      <input
        type="date"
        placeholder="Search by Date"
        value={searchDate}
        onChange={(e) => setSearchDate(e.target.value)}
        style={inputStyle}
      />
      <input
        type="number"
        placeholder="Search by Hours"
        value={searchHours}
        onChange={(e) => setSearchHours(e.target.value)}
        style={inputStyle}
      />
      <input
        type="text"
        placeholder="Search by Role"
        value={searchRole}
        onChange={(e) => setSearchRole(e.target.value)}
        style={inputStyle}
      />
      <input
        type="text"
        placeholder="Search by Organizer"
        value={searchOrganizer}
        onChange={(e) => setSearchOrganizer(e.target.value)}
        style={inputStyle}
      />
    </div>

    <div className="p-5 bg-light">
      <div className="bg-white shadow border" style={containerStyle}>
        <div style={isMobile ? { overflowX: 'auto' } : {}}>
          <table className="table" style={{ fontFamily: "'Carme', sans-serif", minWidth: '1000px' }}>
            <thead>
              <tr>
                <th onClick={() => clickSort('event')}>
                  Event Name
                  <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                    {sortingEventOrder === 'asc' ? '▲' : '▼'}
                  </button>
                </th>
                <th onClick={() => clickSort('date')}>
                  Date of Event
                  <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                    {sortingDateOrder === 'asc' ? '▲' : '▼'}
                  </button>
                </th>
                <th onClick={() => clickSort('hours')}>
                  Hours Worked
                  <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                    {sortingHoursOrder === 'asc' ? '▲' : '▼'}
                  </button>
                </th>
                <th onClick={() => clickSort('role')}>
                  Role
                  <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                    {sortingRoleOrder === 'asc' ? '▲' : '▼'}
                  </button>
                </th>
                <th onClick={() => clickSort('organizer')}>
                  Organizer
                  <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                    {sortingOrganizerOrder === 'asc' ? '▲' : '▼'}
                  </button>
                </th>
                <th>Description</th>
                <th>Contact</th>
                <th>Verified</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData
                .filter(item =>
                  item.event.includes(searchEvent) &&
                  (!searchDate || item.date.includes(searchDate)) &&
                  (!searchHours || item.hours.toString().includes(searchHours)) &&
                  (!searchRole || item.role.includes(searchRole)) &&
                  (!searchOrganizer || item.organizer.includes(searchOrganizer))
                )
                .map((d, i) => (
                  <tr key={i}>
                    <td>{d.event}</td>
                    <td>{d.date}</td>
                    <td>{d.hours}</td>
                    <td>{d.role}</td>
                    <td>{d.organizer}</td>
                    <td>{d.description}</td>
                    <td>{d.contact}</td>
                    <td>
                      <div
                        className={`icon ${d.verified ? 'checkmark' : 'xmark'}`}
                        onClick={() => handleVerifyToggle(d.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        {d.verified ? '✔' : '✖'}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <button 
                          className="btn btn-primary" 
                          onClick={() => navigate(`/admin/user-entries/update/${d.id}`)}
                        >
                          Update
                        </button>
                        <PortfolioDeleteButton pk={d.id} onClick={() => clickDelete(d.id)} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/* Styled Pagination Component */}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={Math.ceil(sortedData.length / entriesPerPage)}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
          />
        </div>
      </div>
    </div>
  </div>
  );
}

export default AdminEntryDetail;
