import React from 'react';

// Function to format the date in MM/DD/YYYY format
const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};

const TodayDate = () => {
    const today = new Date();
    const formattedDate = formatDate(today);

    return (
        <div>
            <p>{formattedDate}</p>
        </div>
    );
};

export default TodayDate;