import Header from '../../components/header';
import '../css/Portfolio.css';
import '../css/checkmark.css';
import '../css/App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import ReactPaginate from 'react-paginate'; // Import ReactPaginate for pagination

const carmeFontStyle = {
    fontFamily: "'Carme', sans-serif",
};

// AXIOS setup for security purposes
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Client Instance with Django URL in order to type the URL only once
const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

// Function to get the CSRF token from the cookie
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function AdminPortfolioSummary() {
    const csrftoken = getCookie('csrftoken');
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [sortedField, setSortedField] = useState(null);
    const [sortingOrder, setSortingOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(0); // Current page for pagination
    const [adminStatus, setAdminStatus] = useState(null);
    const [searchFirstName, setSearchFirstName] = useState(''); // Search by first name
    const [searchLastName, setSearchLastName] = useState(''); // Search by last name
    const [searchEmail, setSearchEmail] = useState(''); // Search by email
    const [searchGradYear, setSearchGradYear] = useState(''); // Filter by graduation year
    const [requirementMetFilter, setRequirementMetFilter] = useState(''); // Filter by requirement met
    const [totalUsers, setTotalUsers] = useState(0); // Total users
    const [totalVolunteerTime, setTotalVolunteerTime] = useState(0); // Total volunteer time
    const [schoolName, setSchoolName] = useState(''); // School name
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Detect mobile
    const itemsPerPage = isMobile ? 10 : 30; // Items per page changes for mobile

    // Get next graduation year (June logic)
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const nextGraduationYear = currentDate.getMonth() >= 5 ? currentYear + 1 : currentYear;

    // Check if the user has admin privileges
    useEffect(() => {
        client.get('/accounts/check-admin-status', { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {
                if (res.data.is_school_administration || res.data.is_school_administration_head) {
                    setAdminStatus(true);
                    setSchoolName(res.data.school); // Set the school name for the title
                } else {
                    navigate('/unauthorized'); // Redirect if not an admin
                }
            })
            .catch((error) => {
                console.error('Error checking admin status:', error); // Log the error
                navigate('/login'); // Redirect to login if the request fails
            });
    }, [csrftoken, navigate]);

    // Fetch the summary list (for admin) - assumes backend filters by school
    useEffect(() => {
        client.get('/admin/entry-summary', { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {
                const totalTime = res.data.reduce((acc, user) => acc + user.total_hours, 0);
                setData(res.data);  // Ensure that only entries from the admin's school are returned
                setTotalUsers(res.data.length); // Set total users
                setTotalVolunteerTime(totalTime); // Set total volunteer hours
            })
            .catch(err => {
                console.error("Error fetching data: ", err); // Log the error for debugging
            });
    }, [csrftoken, adminStatus]);

    const clickSort = (field) => {
        setSortedField(field);
        setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc');
    };

    // Handle pagination page click
    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    // Filter, sort, and paginate the data
    const filteredData = data
        .filter(d =>
            (d.graduation_year >= nextGraduationYear) &&
            (d.first_name.toLowerCase().includes(searchFirstName.toLowerCase())) &&
            (d.last_name.toLowerCase().includes(searchLastName.toLowerCase())) &&
            (d.email.toLowerCase().includes(searchEmail.toLowerCase())) &&
            (!searchGradYear || d.graduation_year === parseInt(searchGradYear)) &&
            (!requirementMetFilter || (requirementMetFilter === 'Yes' ? d.total_hours >= 100 : d.total_hours < 100))
        )
        .sort((a, b) => {
            if (!sortedField) return 0;
            return sortingOrder === 'asc' ? (a[sortedField] < b[sortedField] ? -1 : 1) : (a[sortedField] > b[sortedField] ? -1 : 1);
        });

    // Calculate filtered totals
    const filteredTotalUsers = filteredData.length;
    const filteredTotalVolunteerTime = filteredData.reduce((acc, user) => acc + user.total_hours, 0);
    const percentRequirementMet = filteredTotalUsers > 0 ? (filteredData.filter(d => d.total_hours >= 100).length / filteredTotalUsers) * 100 : 0;

    // Pagination: get only the items for the current page
    const paginatedData = filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const handleDownloadUserEntries = (userId) => {
        client.get(`/admin/entries/${userId}/download`, {
            responseType: 'blob', // Important for handling file downloads
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `entries_${userId}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => console.error('Error downloading the user entries', error));
    };

    const handleDownloadAllEntries = () => {
        client.get(`/admin/entries/download`, {
            responseType: 'blob', // Important for handling file downloads
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'all_entries.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => console.error('Error downloading all entries', error));
    };

    if (!adminStatus) {
        return <div>Loading...</div>; // Show loading spinner until admin status is verified
    }

    return (
        <div>
            <Header />
            <div 
            className="center" 
            style={{
                ...carmeFontStyle,
                textAlign: 'center',
                fontSize: isMobile ? '20px' : '36px', // Adjust font size based on screen size
                padding: isMobile ? '10px' : '20px',  // Adjust padding
                margin: isMobile ? '10px 0' : '20px 0', // Adjust margins
                color: '#333', // Example color
            }}
            >
                <h2>TimberwolveService Volunteer Data For {schoolName}</h2>
            </div>


            {/* Display Totals Based on Filter */}
            <div className="totals" style={{ marginBottom: '20px', textAlign: 'center' }}>
                <p>Total Users on Network: {filteredTotalUsers}</p>
                <p>Total Volunteer Hours on Network: {Math.round(filteredTotalVolunteerTime * 100) / 100}</p>
                <p>Percent of Users Meeting Requirement: {Math.round(percentRequirementMet * 100) / 100}%</p>
                {/* Download All Entries Button */}
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <button
                    onClick={handleDownloadAllEntries}
                    style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#0a4b39', color: 'white' }}
                >
                    Download All Entries
                </button>
            </div>
            </div>

            {/* Search Filters - Centered */}
            <div 
            className="filters" 
            style={{
                display: 'flex',
                flexWrap: 'wrap', // Allow wrapping of elements to prevent clipping
                justifyContent: isMobile ? 'center' : 'space-between', // Center filters on mobile
                alignItems: 'center',
                marginBottom: '20px',
                gap: '10px', // Uniform gap between filters
                padding: isMobile ? '10px' : '20px', // Adjust padding for mobile and desktop
                width: '100%' // Ensure full width for the container
            }}
        >
            <input 
                type="text" 
                placeholder="Search by First Name" 
                value={searchFirstName} 
                onChange={(e) => setSearchFirstName(e.target.value)} 
                style={{
                    flexGrow: 1, // Allow the input to grow and fill the space
                    minWidth: isMobile ? '100%' : '200px', // Full width on mobile
                    padding: '5px',
                    borderRadius: '5px'
                }}
            />
            <input 
                type="text" 
                placeholder="Search by Last Name" 
                value={searchLastName} 
                onChange={(e) => setSearchLastName(e.target.value)} 
                style={{
                    flexGrow: 1,
                    minWidth: isMobile ? '100%' : '200px',
                    padding: '5px',
                    borderRadius: '5px'
                }}
            />
            <input 
                type="text" 
                placeholder="Search by Email" 
                value={searchEmail} 
                onChange={(e) => setSearchEmail(e.target.value)} 
                style={{
                    flexGrow: 1,
                    minWidth: isMobile ? '100%' : '200px',
                    padding: '5px',
                    borderRadius: '5px'
                }}
            />
            <select 
                value={searchGradYear} 
                onChange={(e) => setSearchGradYear(e.target.value)} 
                style={{
                    flexGrow: 1,
                    minWidth: isMobile ? '100%' : '200px',
                    padding: '5px',
                    borderRadius: '5px'
                }}
            >
                <option value="">All Graduation Years</option>
                <option value={nextGraduationYear}>{nextGraduationYear}</option>
                <option value={nextGraduationYear + 1}>{nextGraduationYear + 1}</option>
                <option value={nextGraduationYear + 2}>{nextGraduationYear + 2}</option>
            </select>
            <select 
                value={requirementMetFilter} 
                onChange={(e) => setRequirementMetFilter(e.target.value)} 
                style={{
                    flexGrow: 1,
                    minWidth: isMobile ? '100%' : '200px',
                    padding: '5px',
                    borderRadius: '5px'
                }}
            >
                <option value="">Requirement Met (All)</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </div>
            

            {/* Data Table */}
            <div className="p-5 bg-light">
                <div className="bg-white shadow border">
                    <div style={isMobile ? { overflowX: 'auto' } : {}}>
                    <table className="table" style={carmeFontStyle}>
                <thead>
                    <tr>
                        <th onClick={() => clickSort('first_name')}>
                            First Name
                            <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                                {sortingOrder === 'asc' ? '▲' : '▼'}
                            </button>
                        </th>
                        <th onClick={() => clickSort('last_name')}>
                            Last Name
                            <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                                {sortingOrder === 'asc' ? '▲' : '▼'}
                            </button>
                        </th>
                        {!isMobile && ( // Hide on mobile
                            <>
                                <th onClick={() => clickSort('email')}>
                                    Email
                                    <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                                        {sortingOrder === 'asc' ? '▲' : '▼'}
                                    </button>
                                </th>
                                <th onClick={() => clickSort('graduation_year')}>
                                    Graduation Year
                                    <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                                        {sortingOrder === 'asc' ? '▲' : '▼'}
                                    </button>
                                </th>
                            </>
                        )}
                        <th onClick={() => clickSort('total_hours')}>
                            Total Hours
                            <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                                {sortingOrder === 'asc' ? '▲' : '▼'}
                            </button>
                        </th>
                        <th>Requirement Met</th> {/* Graduation requirement met column */}
                        <th>View Entries</th>
                        <th>Download Entries</th> {/* Download button column */}
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.map((d, i) => (
                        <tr key={i}>
                            <td>{d.first_name}</td> {/* Consistent use of first_name */}
                            <td>{d.last_name}</td> {/* Consistent use of last_name */}
                            {!isMobile && ( // Hide on mobile
                                <>
                                    <td>{d.email}</td> {/* Consistent use of email */}
                                    <td>{d.graduation_year}</td> {/* Consistent use of graduation_year */}
                                </>
                            )}
                            <td>{Math.round(d.total_hours * 100) / 100}</td> {/* Round total hours to nearest hundredths */}
                            <td>{d.total_hours >= 100 ? 'Yes' : 'No'}</td> {/* Graduation requirement met */}
                            <td>
                                <button
                                    onClick={() => navigate(`/admin/user-entries/${d.id}`)} // Fixed template literal
                                    style={{ padding: '5px 10px', borderRadius: '5px', backgroundColor: '#007BFF', color: 'white' }}
                                >
                                    View Details
                                </button>
                            </td>
                            <td>
                                <button
                                    onClick={() => handleDownloadUserEntries(d.id)}
                                    style={{ padding: '5px 10px', borderRadius: '5px', backgroundColor: '#007BFF', color: 'white' }}
                                >
                                    Download Entries
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

                        {/* Pagination Component */}
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={Math.ceil(filteredData.length / itemsPerPage)}  // Calculate total pages
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageClassName={"page-item"}
                            previousClassName={"page-item"}
                            nextClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={isMobile ? 2 : 5} // Adjust page range on mobile
                        />
                    </div>
                </div>
            </div>

            
        </div>
    );
}

export default AdminPortfolioSummary;
