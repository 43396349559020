import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/Opportunities.css';
import Header from '../components/header';
import { useNavigate } from 'react-router-dom';
import background from '../components/assets/background.png';
import TodayDate from '../components/TodayDate';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMediaQuery } from 'react-responsive';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});


function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const Opportunities = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });  // Detect mobile devices

    // Adjust container style based on screen size
    const containerStyle = {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: isMobile ? '80vh' : '60vh',  // Adjust height for mobile
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: isMobile ? 'center' : 'left',  // Center on mobile
        textAlign: isMobile ? 'center' : 'left',
        color: '#042c24',
        fontFamily: "'Anton', sans-serif",
        paddingLeft: isMobile ? '20px' : '135px',  // Adjust padding for mobile
        paddingBottom: '40px',
    };

    const carmeFontStyle = {
        fontFamily: "'Carme', sans-serif",
    };

    // Inline styles for table scroll and layout
    const tableWrapperStyles = {
        overflowX: 'auto',  // Enables horizontal scrolling
        margin: '0 auto',
        width: '100%',  // Ensures the table wrapper takes full width
    };

    const tableStyles = {
        tableContainer: {
            width: isMobile ? '100%' : '90%',  // Full width on mobile
            margin: isMobile ? '20px auto' : '50px auto',
            borderCollapse: 'collapse',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.8)',
        },
        table: {
            width: '100%',
            borderSpacing: '0',
            fontFamily: "'Anton', sans-serif",
            color: '#fff',
            minWidth: '600px',  // Set a minimum width to ensure proper table layout
        },
        headerRow: {
            backgroundColor: '#252624',
            textTransform: 'uppercase',
            color: '#fff',
            letterSpacing: '1px',
        },
        th: {
            padding: isMobile ? '8px' : '14px',  // Adjust padding for mobile
            textAlign: 'left',
            fontSize: isMobile ? '12px' : '16px',  // Adjust font size for mobile
            borderBottom: '3px solid #0a4b39',
        },
        row: {
            backgroundColor: '#1e1e1e',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        },
        rowHover: {
            backgroundColor: '#333333',
        },
        td: {
            padding: isMobile ? '8px' : '12px',  // Adjust padding for mobile
            textAlign: 'left',
            fontSize: isMobile ? '12px' : '16px',
            borderBottom: '3px solid #0a4b39',
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
        profilePicture: {
            width: isMobile ? '30px' : '50px',
            height: isMobile ? '30px' : '50px',
            borderRadius: '50%',
            objectFit: 'cover',
        },
    };

    const paginationStyles = {
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            listStyleType: 'none',
            padding: '10px',
            marginTop: '20px',
        },
        pageItem: {
            margin: '0 5px',
            cursor: 'pointer',
        },
        pageLink: {
            textDecoration: 'none',
            color: '#ffffff',
            padding: isMobile ? '5px 10px' : '10px 15px',  // Adjust padding for mobile
            border: '2px solid #042c24',
            borderRadius: '5px',
            backgroundColor: '#042c24',
            transition: 'background-color 0.3s, color 0.3s',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        },
        pageLinkHover: {
            backgroundColor: '#0a4b39',
            color: '#ffffff',
        },
        active: {
            backgroundColor: '#0a4b39',
            color: '#ffffff',
            border: '2px solid #042c24',
        },
    };

    const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCity, setFilterCity] = useState('');
  const [filterState, setFilterState] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null); // State to track authentication
  const csrftoken = getCookie('csrftoken');

  // Check if user is authenticated, if not redirect to login
  useEffect(() => {
    client.get("/accounts")
      .then(() => setCurrentUser(true))
      .catch(() => {
        setCurrentUser(false);
        navigate('/login');
      });
  }, [navigate]);

  const fetchOpportunities = async () => {
    if (currentUser) { // Fetch opportunities only if user is authenticated
      try {
        const response = await client.get('/volunteer_events/opportunities', {
          headers: {
            'X-CSRFToken': csrftoken
          }
        });
        setOpportunities(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchOpportunities();
  }, [currentUser]); // Dependency on currentUser ensures data is fetched only after authentication check

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterCityChange = (e) => {
        setFilterCity(e.target.value);
    };

    const handleFilterStateChange = (e) => {
        setFilterState(e.target.value);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const filteredOpportunities = opportunities
        .filter((opportunity) =>
            opportunity.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .filter((opportunity) =>
            filterCity ? opportunity.city.toLowerCase().includes(filterCity.toLowerCase()) : true
        )
        .filter((opportunity) =>
            filterState ? opportunity.state.toLowerCase().includes(filterState.toLowerCase()) : true
        );

    const offset = currentPage * itemsPerPage;
    const currentPageData = filteredOpportunities.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(filteredOpportunities.length / itemsPerPage);
    const uniqueCities = Array.from(new Set(opportunities.map(opportunity => opportunity.city)));

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading opportunities: {error.message}</p>;

    return (
        <>
            <Header />
            <div style={containerStyle}>
                <div style={{ textAlign: 'left', width: '100%' }}>  {/* Ensures that the parent div aligns content to the left */}
                    <h1 style={{ fontSize: isMobile ? '25px' : '40px', textShadow: '2px 2px 5px #000', textAlign: 'left', fontWeight: 'bold', color: '#37bc8d' }}>
                        {TodayDate()}
                    </h1>
                </div>
                <div style={{ textAlign: 'left', width: '100%' }}>  {/* Ensures that the parent div aligns content to the left */}
                    <h1 style={{ fontSize: isMobile ? '35px' : '80px', textShadow: '2px 2px 5px #000', fontWeight: 'bold', color: '#d2da19' }}>VOLUNTEER OPPORTUNITIES</h1>
                </div>
                <div style={{ textAlign: 'left', width: '100%' }}>  {/* Ensures that the parent div aligns content to the left */}
                    <h1 style={{ fontSize: isMobile ? '14px' : '20px', textShadow: '2px 2px 5px #000', fontWeight: 'bold', color: '#94c547' }}>FIND THE BEST VOLUNTEER OPPORTUNITIES HERE!</h1>
                </div>
            </div>
            <div className="opportunities-container" style={carmeFontStyle}>
                <div style={tableWrapperStyles}>
                    <table style={tableStyles.tableContainer}>
                        <thead>
                            <tr style={tableStyles.headerRow}>
                                <th style={tableStyles.th} colSpan="5">TimberwolveService Opportunities</th>
                            </tr>
                        </thead>

                        <thead>
                            <tr style={tableStyles.headerRow}>
                                <th style={tableStyles.th} colSpan="2">
                                    <input
                                        type="text"
                                        placeholder="Search by name"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        className="search-input"
                                        style={{ width: '100%' }} // Make input fill the th element
                                    />
                                </th>

                                <th style={tableStyles.th} colSpan="2">
                                    <input
                                        type="text"
                                        placeholder="Filter by state"
                                        value={filterState}
                                        onChange={handleFilterStateChange}
                                        className="filter-input"
                                        style={{ width: '100%' }} // Make input fill the th element
                                    />
                                </th>

                                <th style={tableStyles.th} colSpan="1">
                                    <select
                                        value={filterCity}
                                        onChange={handleFilterCityChange}
                                        className="filter-input"
                                        style={{ width: '100%' }} // Make select fill the th element
                                    >
                                        <option value="">Filter by city</option>
                                        {uniqueCities.map((city, index) => (
                                            <option key={index} value={city}>{city}</option>
                                        ))}
                                    </select>
                                </th>
                            </tr>
                        </thead>

                        <thead>
                            <tr style={{ ...tableStyles.headerRow, backgroundColor: '#0a4b39' }}>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}>Name</th>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}>Location</th>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}>Hours</th>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}>Description</th>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}>Link</th>
                            </tr>
                        </thead>

                        <tbody>
                            {currentPageData.map((opportunity, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        ...tableStyles.row,
                                        backgroundColor: index % 2 === 0 ? '#b1e3b9' : '#e6edca',
                                    }}
                                    onMouseOver={(e) => {
                                        e.currentTarget.style.backgroundColor = '#5cbf6d';
                                    }}
                                    onMouseOut={(e) => {
                                        e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#b1e3b9' : '#e6edca';
                                    }}
                                >
                                    <td style={tableStyles.td}>{opportunity.name}</td>
                                    <td style={tableStyles.td}>{opportunity.address}, {opportunity.city}, {opportunity.state}, {opportunity.zip_code}</td>
                                    <td style={tableStyles.td}>{opportunity.hours}</td>
                                    <td style={tableStyles.td}>{opportunity.description}</td>
                                    <td style={tableStyles.td}>
                                        <Link to={opportunity.url}>
                                            <Button variant="success">View Opportunity</Button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={isMobile ? 1 : 5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName={'page-link'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    activeLinkClassName={'active'}
                    breakLinkStyle={paginationStyles.break}
                    pageLinkStyle={paginationStyles.pageLink}
                    activeStyle={paginationStyles.active}
                />
            </div>
        </>
    );
};

export default Opportunities;