import React from 'react';
import { useNavigate } from 'react-router-dom';

function PortfolioCreateButton() {
    const navigate = useNavigate();
    const handleClick = () => {
      navigate('/portfolio-create-event');
    };
  
    return (
      <button class="left" onClick={handleClick} style={{ backgroundColor: '#ffffff', color: "#000000", border: '2px solid', // This specifies that the button has a border
        borderColor: '#000', // This specifies the color of the border
        padding: '10px 20px',
        borderRadius: '5px' }}>
        Record Your Event
      </button>
    );
  }
  
  export default PortfolioCreateButton;