import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Header from '../components/header';

// AXIOS VARIABLES ARE FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Client Instance with Django URL in order to type the url only once
const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
  });

const VerifyEmail = () => {
  const { token } = useParams(); // Get the token from the URL
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Send the token to the backend for verification
    client.get(`/accounts/verify-email/${token}/`)
      .then(response => {
        setMessage(response.data.detail);
        setError(false);
        setLoading(false);
      })
      .catch(error => {
        console.log("Failed")
        setMessage("Verification failed. This link may have expired or is invalid.");
        setError(true);
        setLoading(false);
      });
  }, [token]);

  const handleGoToLogin = () => {
    navigate('/login'); // Navigate to the login page after successful verification
  };

  return (
    <>
    <Header />
    <Container className="verification-container">
      <h2>Email Verification</h2>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <div>
          <p style={{ color: error ? 'red' : 'green' }}>{message}</p>
          {!error && (
            <Button variant="primary" onClick={handleGoToLogin}>
              Go to Login
            </Button>
          )}
        </div>
      )}
    </Container>
    </>
  );
};

export default VerifyEmail;
