import Header from '../components/header'
import './css/Portfolio.css';
import PortfolioCreateButton from '../components/portfolio_create_button'
import PortfolioDeleteButton from '../components/portfolio_delete_button'
import PortfolioUpdateButton from '../components/portfolio_update_button'
import './css/checkmark.css';
import './css/App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
// import Container from 'react-bootstrap/Container';
// import Navbar from 'react-bootstrap/Navbar';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';

const carmeFontStyle = {
    fontFamily: "'Carme', sans-serif",
  };

//AXIOS VARIABLES ARE FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

//Client Instance with Django URL in order to type the url only once
const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

// Function to get the CSRF token from the cookie. Function in Django Documentation
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  function Portfolio() {
    const csrftoken = getCookie('csrftoken');
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [sortedField, setSortedField] = useState(null);
    const [sortingEventOrder, setSortingEventOrder] = useState('desc');
    const [sortingDateOrder, setSortingDateOrder] = useState('desc');
    const [sortingHoursOrder, setSortingHoursOrder] = useState('desc');
    const [sortingRoleOrder, setSortingRoleOrder] = useState('desc');
    const [sortingOrganizerOrder, setSortingOrganizerOrder] = useState('desc');
    const [searchEvent, setSearchEvent] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [searchHours, setSearchHours] = useState('');
    const [searchRole, setSearchRole] = useState('');
    const [searchOrganizer, setSearchOrganizer] = useState('');
    const [totalVerifiedHours, setTotalVerifiedHours] = useState('');
    const [totalVerifiedEntries, setTotalVerifiedEntries] = useState('');
    

    // UseMediaQuery to detect mobile devices
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const inputStyle = {
        width: isMobile ? '100%' : '230px',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        margin: '5px 0',
        boxSizing: 'border-box', // Ensures padding doesn't affect width
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Center inputs horizontally
        flexWrap: isMobile ? 'wrap' : 'nowrap', // Wrap inputs on mobile
        gap: '20px', // Space between inputs
        padding: '20px', // Padding around the input section
        backgroundColor: '#f9f9f9', // Light background color
        borderRadius: '10px', // Rounded corners for the container
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Light shadow for a clean look
    };

    const inputContainerStyle = {
        marginLeft: isMobile ? '0' : '20px',
        marginRight: isMobile ? '0' : '20px',
        width: isMobile ? '100%' : 'auto',
    };
    
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        client.get("/accounts")
            .then(() => setCurrentUser(true))
            .catch(() => {
                setCurrentUser(false);
                navigate('/login');
            });
    }, [navigate]);

    useEffect(() => {
        client.get('/volunteer_hours_portfolio/portfolio', {
            headers: { 'X-CSRFToken': csrftoken }
        })
        .then(res => {
            // Filter entries to exclude those in the recycling bin
            const filteredData = res.data.filter(item => !item.recycling_bin);
    
            setData(filteredData);
    
            // Calculate total verified hours and number of verified entries
            const verifiedEntries = filteredData.filter(item => item.verified);
            const totalHours = verifiedEntries.reduce((acc, item) => acc + item.hours, 0);
    
            setTotalVerifiedHours(totalHours); // Set the total verified hours
            setTotalVerifiedEntries(verifiedEntries.length); // Set the total number of verified entries
        })
        .catch(err => console.error('Error fetching data:', err));
    }, [csrftoken]);    

    const clickSort = (field) => {
        setSortedField(field);
        switch (field) {
            case 'event':
                setSortingEventOrder(sortingEventOrder === 'asc' ? 'desc' : 'asc');
                break;
            case 'date':
                setSortingDateOrder(sortingDateOrder === 'asc' ? 'desc' : 'asc');
                break;
            case 'hours':
                setSortingHoursOrder(sortingHoursOrder === 'asc' ? 'desc' : 'asc');
                break;
            case 'role':
                setSortingRoleOrder(sortingRoleOrder === 'asc' ? 'desc' : 'asc');
                break;
            case 'organizer':
                setSortingOrganizerOrder(sortingOrganizerOrder === 'asc' ? 'desc' : 'asc');
                break;
            default:
                break;
        }
    };

    const clickDelete = (pk) => {
        setData(data.filter(item => item.id !== pk));
        client.delete(`/volunteer_hours_portfolio/delete_entry/${pk}`, {
            headers: { 'X-CSRFToken': csrftoken }
        })
        .then(res => console.log('Deleted Event:', res.data))
        .catch(err => console.error('Error deleting event:', err));
    };

    const sortedData = data.sort((a, b) => {
        if (!sortedField) return 0;
        const order = sortedField === 'event' ? sortingEventOrder : sortedField === 'date' ? sortingDateOrder : sortedField === 'hours' ? sortingHoursOrder : sortedField === 'role' ? sortingRoleOrder : sortingOrganizerOrder;
        return order === 'asc' ? a[sortedField] < b[sortedField] ? -1 : 1 : a[sortedField] > b[sortedField] ? -1 : 1;
    });

    return (
        <div>
        <Header>
        </Header>
        <div class="center" style={carmeFontStyle}>
            <h2>Volunteer Hours Portfolio</h2>
        </div>
        
        <div className="portfolio-description" style={{marginBottom:'30px', fontFamily: "'Carme', sans-serif"}}>
            <p><br></br>Welcome to the Hour Tracking Page! This page allows you to log and keep detailed records of all your events. Here's what you can record: <br></br><br></br></p>
            <li><b>Event Name:</b> Input the name of the event you participated in.</li>
            <li><b>Date of Event:</b> Specify the exact date when the event took place.</li>
            <li><b>Hours Worked:</b> Record the number of hours you contributed to the event.</li>   
            <li><b>Role:</b> Describe the role you played or the tasks you performed during the event.</li>
            <li><b>Organizer:</b> Enter the name of the organization or individual who organized the event.</li>
            <li><b>Description:</b> Provide a detailed description of the event, including any significant activities or outcomes.</li>
            <p><br></br><br></br>
                Using this page, you can easily keep track of your hours and events, ensuring you have a comprehensive record of your contributions and experiences.</p>
                <p>
            <b>Total Verified Volunteer Hours:</b> {totalVerifiedHours} 
            <span style={{ paddingLeft: '20px' }}></span> 
            <b>Total Number of Verified Entries:</b> {totalVerifiedEntries}
</p>
        </div>

            <div className="portfolio-button" style={carmeFontStyle}>
                <PortfolioCreateButton />
            </div>

            <div className="center" style={containerStyle}>
            <div style={inputContainerStyle}>
                <input
                    type="text"
                    value={searchEvent}
                    onChange={(e) => setSearchEvent(e.target.value)}
                    placeholder="Search by Event Name"
                    style={inputStyle} // Apply the input styles
                />
            </div>
            <div style={inputContainerStyle}>
                <input
                    type="date"
                    value={searchDate}
                    onChange={(e) => setSearchDate(e.target.value)}
                    placeholder="Search by Date"
                    style={inputStyle} // Apply the input styles
                />
            </div>
            <div style={inputContainerStyle}>
                <input
                    type="number"
                    value={searchHours}
                    onChange={(e) => setSearchHours(e.target.value)}
                    placeholder="Search by Hours Worked"
                    style={inputStyle} // Apply the input styles
                />
            </div>
            <div style={inputContainerStyle}>
                <input
                    type="text"
                    value={searchRole}
                    onChange={(e) => setSearchRole(e.target.value)}
                    placeholder="Search by Role"
                    style={inputStyle} // Apply the input styles
                />
            </div>
            <div style={inputContainerStyle}>
                <input
                    type="text"
                    value={searchOrganizer}
                    onChange={(e) => setSearchOrganizer(e.target.value)}
                    placeholder="Search by Organizer"
                    style={inputStyle} // Apply the input styles
                />
            </div>
        </div>

            <div className="p-5 bg-light">
                <div className="bg-white shadow border">
                    {/* Conditionally apply overflowX for mobile */}
                    <div style={isMobile ? { overflowX: 'auto' } : {}}>
                        <table className="table" style={carmeFontStyle}>
                            <thead>
                                <tr>
                                    <th onClick={() => clickSort('event')}>
                                        Event Name
                                        <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                                            {sortingEventOrder === 'asc' ? '▲' : '▼'}
                                        </button>
                                    </th>
                                    <th onClick={() => clickSort('date')}>
                                        Date of Event
                                        <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                                            {sortingDateOrder === 'asc' ? '▲' : '▼'}
                                        </button>
                                    </th>
                                    <th onClick={() => clickSort('hours')}>
                                        Hours Worked
                                        <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                                            {sortingHoursOrder === 'asc' ? '▲' : '▼'}
                                        </button>
                                    </th>
                                    <th onClick={() => clickSort('role')}>
                                        Role
                                        <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                                            {sortingRoleOrder === 'asc' ? '▲' : '▼'}
                                        </button>
                                    </th>
                                    <th onClick={() => clickSort('organizer')}>
                                        Organizer
                                        <button style={{ border: 'none', backgroundColor: 'transparent', color: 'black' }}>
                                            {sortingOrganizerOrder === 'asc' ? '▲' : '▼'}
                                        </button>
                                    </th>
                                    <th>Description</th>
                                    <th>Contact</th>
                                    <th>Verified</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData
                                    .filter(item =>
                                    item.event.includes(searchEvent) &&
                                    (!searchDate || item.date.includes(searchDate)) &&
                                    (!searchHours || item.hours.toString().includes(searchHours)) &&
                                    (!searchRole || item.role.includes(searchRole)) &&
                                    (!searchOrganizer || item.organizer.includes(searchOrganizer))
                                    )
                                    .map((d, i) => (
                                    <tr key={i}>
                                        <td>{d.event}</td>
                                        <td>{d.date}</td>
                                        <td>{d.hours}</td>
                                        <td>{d.role}</td>
                                        <td>{d.organizer}</td>
                                        <td>{d.description}</td>
                                        <td>{d.contact}</td>
                                        <td>
                                        <div 
                                            className={`icon ${d.verified ? 'checkmark' : 'xmark'}`}
                                            onClick={() => navigate('/about-verification')} // Navigate when clicked
                                            style={{ cursor: 'pointer' }}  // Change cursor to pointer
                                            >
                                            {d.verified ? '✔' : '✖'}
                                        </div>
                                        </td>
                                        <td>
                                        <div className="d-flex">
                                            <PortfolioUpdateButton pk={d.id} />
                                            <PortfolioDeleteButton pk={d.id} onClick={clickDelete} />
                                        </div>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Portfolio;