import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import ReactPaginate from 'react-paginate'; // Import ReactPaginate for pagination
import '../css/RecyclingBin.css'; // Assume you have this file for your custom styles
import Header from '../../components/header';

// AXIOS VARIABLES FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Client Instance with Django URL in order to type the URL only once
const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

// Function to get the CSRF token from the cookie (Function from Django documentation)
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function RecyclingBin() {
    const csrftoken = getCookie('csrftoken');
    const [entries, setEntries] = useState([]);
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [pageNumber, setPageNumber] = useState(0); // Add state for pagination

    const entriesPerPage = 20; // Set number of entries per page
    const pagesVisited = pageNumber * entriesPerPage;

    const [searchUser, setSearchUser] = useState('');
    const [searchEvent, setSearchEvent] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [searchDescription, setSearchDescription] = useState('');
    const [searchContact, setSearchContact] = useState('');

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        client.get('/volunteer_hours_portfolio/recycling-bin', {
            headers: { 'X-CSRFToken': csrftoken }
        })
        .then(res => setEntries(res.data.entries_in_bin))
        .catch(err => console.error('Error fetching recycling bin entries:', err));
    }, [csrftoken]);

    const handleSelectEntry = (id) => {
        if (selectedEntries.includes(id)) {
            setSelectedEntries(selectedEntries.filter(entryId => entryId !== id));
        } else {
            setSelectedEntries([...selectedEntries, id]);
        }
    };

    const handleRecoverSelected = (id) => {
        client.post(`/volunteer_hours_portfolio/recycling-bin/${id}`, {}, {
            headers: { 'X-CSRFToken': csrftoken }
        })
        .then(res => {
            setEntries(entries.filter(entry => entry.id !== id));
            setSelectedEntries(selectedEntries.filter(entryId => entryId !== id)); // Clear the selection for recovered entry
        })
        .catch(err => console.error('Error recovering entry:', err));
    };

    const handlePermanentlyDeleteSelected = () => {
        client.delete('/volunteer_hours_portfolio/recycling-bin/permanent-delete', {
            headers: { 'X-CSRFToken': csrftoken },
            data: { entry_ids: selectedEntries } // Send entry IDs to delete
        })
        .then(res => {
            setEntries(entries.filter(entry => !selectedEntries.includes(entry.id)));
            setSelectedEntries([]); // Clear the selection
        })
        .catch(err => console.error('Error permanently deleting entries:', err));
    };

    const filteredEntries = entries.filter(entry =>
        entry.user_email.toLowerCase().includes(searchUser.toLowerCase()) &&
        entry.event.toLowerCase().includes(searchEvent.toLowerCase()) &&
        entry.date.includes(searchDate) &&
        entry.description.toLowerCase().includes(searchDescription.toLowerCase()) &&
        entry.contact.toLowerCase().includes(searchContact.toLowerCase())
    );

    // Pagination Logic
    const displayEntries = filteredEntries.slice(pagesVisited, pagesVisited + entriesPerPage);

    const pageCount = Math.ceil(filteredEntries.length / entriesPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <>
        <Header/>
        <div className="recycling-bin">
            <h2>Volunteer Entry Recycling Bin</h2>

            <div className="search-fields">
                <input
                    type="text"
                    placeholder="Search by User"
                    value={searchUser}
                    onChange={(e) => setSearchUser(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Search by Event"
                    value={searchEvent}
                    onChange={(e) => setSearchEvent(e.target.value)}
                />
                <input
                    type="date"
                    placeholder="Search by Date"
                    value={searchDate}
                    onChange={(e) => setSearchDate(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Search by Description"
                    value={searchDescription}
                    onChange={(e) => setSearchDescription(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Search by Contact"
                    value={searchContact}
                    onChange={(e) => setSearchContact(e.target.value)}
                />
            </div>

            {displayEntries.length === 0 ? (
                <p>No entries found in the recycling bin.</p>
            ) : (
                <div className="scrollable-table">
                    <table className="entry-table">
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>User</th>
                                <th>Event</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Contact</th>
                                <th>Hours Worked</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayEntries.map(entry => (
                                <tr key={entry.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedEntries.includes(entry.id)}
                                            onChange={() => handleSelectEntry(entry.id)}
                                        />
                                    </td>
                                    <td>{entry.user_email}</td>
                                    <td>{entry.event}</td>
                                    <td>{entry.date}</td>
                                    <td>{entry.description}</td>
                                    <td>{entry.contact}</td>
                                    <td>{entry.hours}</td>
                                    <td>
                                        <button onClick={() => handleRecoverSelected(entry.id)} className="recover-btn">
                                            Recover
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {selectedEntries.length > 0 && (
                <div className="actions">
                    <button onClick={handlePermanentlyDeleteSelected} className="delete-btn">
                        Permanently Delete Selected
                    </button>
                </div>
            )}

            {/* Pagination Component */}
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBtns"}
                previousLinkClassName={"previousBtn"}
                nextLinkClassName={"nextBtn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
            />
        </div>
        </>
    );
}

export default RecyclingBin;
