import Header from '../components/header';
import './css/App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';


export default function PortfolioForm_Update() {

  return (
    <>
      <Header />
      <div className="center form-container">
        <h1>About Verification on TimberwolveService</h1>
        <p>Whenever creating a new entry in your portfolio, an automated email will be sent to the email specified by the contact field. The email will contain the entry information, along with a link that will redirect them to a page for the recepient to reject or accept the volunteer entry as valid. 
        If the contact recepient accepts the entry,  a checkmark will appear under the verified entry in the portfolio. If the contact recepient rejects the entry, it will be automatically deleted from the user's portfolio. It is the responsibility of the user to ensure that the contact received the email.</p>
        <p>
        Only entries that have been verified will be sent to your organization's administration and considered on the TimberwolveService leaderboard. All entries that have not been verified within a specified time period of about more than a month will be automatically deleted by the system
        and would require the user to recreate the entry.
        </p>
        <p>
        On the other hand, when you confirm an update of any entry, the updated entry will become unverified, and an automated email to the contact of the updated entry will receive an email upon deciding whether or not to validate the entry.
        </p>
      </div>
    </>
  );
}
