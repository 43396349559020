import './css/Home.css';
import Header from '../components/header';
import LoginButton from '../components/login_button';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from '../components/assets/logo.png'; 
import background from '../components/assets/background.png';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { Helmet } from 'react-helmet';

export default function Home() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Detect mobile devices
  const navigate = useNavigate();

  // Adjust container style based on device
  const containerStyle = {
      backgroundImage: `url(${background})`,
      backgroundSize: isMobile ? 'contain' : 'cover',  // Dynamic resizing of the image
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: '#0a4b39',  // Background color to blend with the image
      height: isMobile ? '100vh' : '90vh',  // Use full viewport height on mobile
      minHeight: '100vh',  // Ensures full screen coverage
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#ffffff',
  };

  const navBarStyle = {
    backgroundColor: '#0a4b39',
    width: '100%',
    paddingTop: '0px',
    paddingBottom: '0px',
  };

  const alertStyle = {
    width: '100%',
    backgroundColor: '#ffd700',
    color: '#333',
    padding: isMobile ? '10px' : '15px',
    textAlign: 'center',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: isMobile ? '14px' : '18px',
    position: 'relative',
    top: '0',
    zIndex: '10',
  };

  const learnMoreButtonStyle = {
    backgroundColor: '#0a4b39',
    color: '#fff',
    border: 'none',
    padding: '5px 10px',
    marginLeft: '10px',
    cursor: 'pointer',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: isMobile ? '12px' : '16px',
  };

  const brandStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };

  const montserratFontStyle = {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: isMobile ? '16px' : '24px',  // Adjust font size for mobile
    color: "#c9e265",
    textAlign: 'center',
    paddingLeft: '20px'
  };

  const loginButtonContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: isMobile ? '200px' : '400px',  // Adjust padding for mobile
      height: '100%',
  };

  const handleLoginRedirect = (e) => {
      e.preventDefault();
      navigate('/login');
    };
  
  const handleLoginAbout = (e) => {
    e.preventDefault();
    navigate('/about');
  };

  const handleLeaderboardRedirect = (e) => {
    e.preventDefault();
    navigate('/leaderboard');
  };

  const handleLearnMoreRedirect = (e) => {
    e.preventDefault();
    navigate('/release-notes');
  };

  return (
      <>
        <Helmet>
          <title>TimberwolveService</title>
          <meta name="description" content="This is a custom meta description for this page." />
          <meta name="keywords" content="keyword1, keyword2, keyword3" />
        </Helmet>

        <Navbar style={navBarStyle} data-bs-theme="dark" expand="lg">
          <Container className="header-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Navbar.Brand href="/" style={brandStyle}>
              <img
                src={logo}
                alt="logo"
                width={isMobile ? '40' : '55'}
                height={isMobile ? '50' : '70'}
                className="d-inline-block align-top"
              />{' '}
              <span style={montserratFontStyle}><b>TimberwolveService</b></span>
            </Navbar.Brand>

            {/* Toggle button for mobile */}
            <Navbar.Toggle aria-controls="navbarResponsive" />

            {/* Collapsible content */}
            <Navbar.Collapse id="navbarResponsive" className="justify-content-end">
              <Nav className="ms-auto" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                <button 
                  onClick={handleLeaderboardRedirect} 
                  className="dropdown-item" 
                  style={{ 
                    border: 'none', 
                    background: 'none', 
                    display: 'inline-flex',
                    alignItems: 'center',
                    color: '#b0d5cd', 
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: isMobile ? '14px' : '18px',
                    padding: '8px 16px',
                  }}
                >
                  Leaderboard
                </button>

                <button 
                  onClick={handleLoginAbout} 
                  className="dropdown-item" 
                  style={{ 
                    border: 'none', 
                    background: 'none', 
                    display: 'inline-flex',
                    alignItems: 'center',
                    color: '#b0d5cd',  
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: isMobile ? '14px' : '18px',
                    padding: '8px 16px',
                  }}
                >
                  About
                </button>

                <button 
                  onClick={handleLoginRedirect} 
                  className="dropdown-item" 
                  style={{ 
                    border: 'none', 
                    background: 'none', 
                    display: 'inline-flex',
                    alignItems: 'center',
                    color: '#b0d5cd',  
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: isMobile ? '14px' : '18px',
                    padding: '8px 16px',
                  }}
                >
                  Login
                </button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* Alert message */}
        <div style={alertStyle}>
          TimberwolveService has moved to a new system and server. 
          <button style={learnMoreButtonStyle} onClick={handleLearnMoreRedirect}>Learn more about the changes by clicking here</button>
        </div>

        <div style={containerStyle}>
            <div style={loginButtonContainerStyle}>
                <LoginButton />
            </div>
        </div>
      </>
  );
}
