import './css/App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export default function Post_Event() {
  const csrftoken = getCookie('csrftoken');
  const navigate = useNavigate();
  const [ErrorCreate, setErrorCreate] = useState(null);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [location, setLocation] = useState('');
  const [maxattendees, setMaxattendees] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    client.get("/accounts")
      .then(function (res) {
        setCurrentUser(true);
      })
      .catch(function (error) {
        setCurrentUser(false);
        navigate('/login');
      });
  }, []);

  const validateForm = () => {
    const errors = {};
    const datePattern = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    const timePattern = /^(0?[1-9]|1[0-2]):([0-5][0-9]) ?([AaPp][Mm])$/;

    if (!date.match(datePattern)) {
      errors.date = 'Date must be in MM/DD/YYYY format';
    }

    if (!time.match(timePattern)) {
      errors.time = 'Time must be in HH:MM AM/PM format';
    }

    if (!name) errors.name = 'Event name is required';
    if (!description) errors.description = 'Description is required';
    if (!location) errors.location = 'Location is required';
    if (!maxattendees) errors.maxattendees = 'Max attendees is required';
    if (maxattendees <= 0) errors.maxattendees = 'Max attendees must be greater than 0';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const PostEvent = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    client.post(
      "/volunteer_events/post_event",
      {
        name: name,
        description: description,
        date: date,
        time: time,
        location: location,
        max_attendees: maxattendees,
      },
      {
        headers: {
          'X-CSRFToken': csrftoken
        }
      }
    ).then(function (res) {
      navigate('/your-events');
    }).catch(function (ErrorCreate) {
      if (ErrorCreate.response) {
        setErrorCreate("Error response: " + ErrorCreate.response.data);
      } else if (ErrorCreate.request) {
        setErrorCreate("Error request: " + ErrorCreate.request);
      } else {
        setErrorCreate("Error: " + ErrorCreate.message);
      }
    });
  };

  const handleChange = (event) => {
    const inputValue = parseInt(event.target.value);
    setMaxattendees(inputValue);
  };

  if (currentUser) {
    return (
      <>
        <Header />
        <div className="center form-container" style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px' }}>
          <h1 className="form-title">Post an Event</h1>
          <Form onSubmit={PostEvent}>
            <Form.Group className="mb-3" controlId="formBasicEventName">
              <Form.Label>Event Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Event Name" value={name} onChange={e => setName(e.target.value)} isInvalid={!!formErrors.name} />
              <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" placeholder="Enter Description" value={description} onChange={e => setDescription(e.target.value)} isInvalid={!!formErrors.description} />
              <Form.Control.Feedback type="invalid">{formErrors.description}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Label>Date MM/DD/YYYY</Form.Label>
              <Form.Control type="text" placeholder="Enter Date" value={date} onChange={e => setDate(e.target.value)} isInvalid={!!formErrors.date} />
              <Form.Control.Feedback type="invalid">{formErrors.date}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicTime">
              <Form.Label>Time HH:MM AM/PM</Form.Label>
              <Form.Control type="text" placeholder="Enter Time" value={time} onChange={e => setTime(e.target.value)} isInvalid={!!formErrors.time} />
              <Form.Control.Feedback type="invalid">{formErrors.time}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicLocation">
              <Form.Label>Location</Form.Label>
              <Form.Control type="text" placeholder="Enter Location" value={location} onChange={e => setLocation(e.target.value)} isInvalid={!!formErrors.location} />
              <Form.Control.Feedback type="invalid">{formErrors.location}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMaxattendees">
              <Form.Label>Maximum Attendees</Form.Label>
              <Form.Control type="number" placeholder="Enter Max Attendees" value={maxattendees} onChange={handleChange} isInvalid={!!formErrors.maxattendees} />
              <Form.Control.Feedback type="invalid">{formErrors.maxattendees}</Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit">
              Post Event
            </Button>
          </Form>
        </div>
      </>
    )
  }
}
