import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/header';
import './css/Update_Account_Profile.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

// AXIOS VARIABLES FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '='))
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return cookieValue;
}

// Define Montserrat font style
const montserratFontStyle = {
  fontFamily: "'Montserrat', sans-serif",
};

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export default function Update_Account_Profile() {
  const navigate = useNavigate();
  const csrftoken = getCookie('csrftoken');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    school: '',
    graduation_year: '',
    profile_picture: '', // This will hold the URL of the existing profile picture
  });
  const [newProfilePicture, setNewProfilePicture] = useState(null); // This will handle the new picture upload
  const [errors, setErrors] = useState({}); // For custom validation errors
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // State to track admin status

  const schoolOptions = ['UCLA', 'Larchmont Charter High School'];

  const inputStyle = {
    fontFamily: "'Montserrat', sans-serif", // Apply Montserrat font to input text
    padding: '10px',
    fontSize: '16px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    margin: '5px 0',
    width: '100%', // Make input take full width of the container
  };

  // Check if user is authenticated, if not redirect to login
  useEffect(() => {
    client
      .get('/accounts')
      .then(() => setCurrentUser(true))
      .catch(() => {
        setCurrentUser(false);
        navigate('/login'); // Redirect to login if not authenticated
      });
  }, [navigate]);

  // Fetch admin status
  useEffect(() => {
    if (currentUser) {
      client
        .get('/accounts/check-admin-status', {
          headers: {
            'X-CSRFToken': csrftoken,
          },
        })
        .then((res) => {
          setIsAdmin(
            res.data.is_school_administration || res.data.is_school_administration_head
          );
        })
        .catch(() => setIsAdmin(false));
    }
  }, [currentUser, csrftoken]);

  useEffect(() => {
    if (currentUser) {
      client
        .get('/accounts/profile', {
          headers: {
            'X-CSRFToken': csrftoken,
          },
        })
        .then((response) => {
          setFormData({
            first_name: response.data.first_name || '',
            last_name: response.data.last_name || '',
            school: response.data.school || '',
            graduation_year: response.data.graduation_year || '',
            profile_picture: response.data.profile_picture || '', // Set the profile picture URL
          });
        })
        .catch((error) => {
          console.error('Error fetching profile data:', error);
        });
    }
  }, [csrftoken, currentUser]);

  const validate = () => {
    let formIsValid = true;
    const errors = {};

    if (!formData.first_name) {
      formIsValid = false;
      errors.first_name = 'First name is required';
    }

    if (!formData.last_name) {
      formIsValid = false;
      errors.last_name = 'Last name is required';
    }

    if (!formData.school) {
      formIsValid = false;
      errors.school = 'School is required';
    }

    if (
      !formData.graduation_year ||
      formData.graduation_year < 1900 ||
      formData.graduation_year > new Date().getFullYear() + 10
    ) {
      formIsValid = false;
      errors.graduation_year = 'Please enter a valid graduation year';
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setNewProfilePicture(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('first_name', formData.first_name);
    formDataToSubmit.append('last_name', formData.last_name);
    formDataToSubmit.append('school', formData.school);
    formDataToSubmit.append('graduation_year', formData.graduation_year);
    if (newProfilePicture) {
      formDataToSubmit.append('profile_picture', newProfilePicture);
    }

    client
      .post('/accounts/profile', formDataToSubmit, {
        headers: {
          'X-CSRFToken': csrftoken,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        alert('Profile updated successfully!');
        setFormData({
          ...formData,
          profile_picture: response.data.profile_picture, // Update with new profile picture URL if provided
        });
      })
      .catch((error) => {
        console.error('Error updating profile:', error);

        // Check if it's a file size exceeded error
        if (error.response && error.response.data && error.response.data.error) {
          alert(error.response.data.error); // Display the server error message
        } else {
          alert('Failed to update profile.');
        }
      });
  };

  return (
    <>
      <Header />
      <div className="update-profile-container-game" style={montserratFontStyle}>
        <div style={{ fontWeight: 'bold', fontSize: '30px' }}>Update Account Profile</div>
        <form onSubmit={handleSubmit} className="update-profile-form-game">
          <div className="form-group-game">
            <label>First Name:</label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              className="form-control-game"
              style={inputStyle} // Apply the input style
              required
            />
            {errors.first_name && <div className="error">{errors.first_name}</div>}
          </div>
          <div className="form-group-game">
            <label>Last Name:</label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              className="form-control-game"
              style={inputStyle} // Apply the input style
              required
            />
            {errors.last_name && <div className="error">{errors.last_name}</div>}
          </div>
          <div className="form-group-game">
            <label>School:</label>
            {isAdmin && (
              <span style={{ color: 'red', marginLeft: '10px' }}>(Locked for Admin)</span>
            )}
            <select
              name="school"
              value={formData.school}
              onChange={handleInputChange}
              className="form-control-game"
              style={{
                ...inputStyle,
                backgroundColor: isAdmin ? '#f0f0f0' : '',  // Gray out if locked
                cursor: isAdmin ? 'not-allowed' : 'pointer',  // Change cursor when locked
              }}
              disabled={isAdmin}  // Disable for admin accounts
            >
              <option value="">Select School</option>
              {schoolOptions.map((school) => (
                <option key={school} value={school}>
                  {school}
                </option>
              ))}
            </select>
            {errors.school && <div className="error">{errors.school}</div>}
          </div>

          <div className="form-group-game">
            <label>Graduation Year:</label>
            <select
              name="graduation_year"
              value={formData.graduation_year}
              onChange={handleInputChange}
              className="form-control-game"
              style={inputStyle} // Apply the input style
              required
            >
              <option value="">Select Graduation Year</option>
              {[...Array(12)].map((_, i) => {
                const year = 2022 + i;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
            {errors.graduation_year && <div className="error">{errors.graduation_year}</div>}
          </div>

          <div className="form-group-game">
            <label>Profile Picture:</label>
            {formData.profile_picture && (
              <div className="profile-picture-link-game">
                <a href={formData.profile_picture} target="_blank" rel="noopener noreferrer">
                  View Current Profile Picture
                </a>
              </div>
            )}
            <input
              type="file"
              name="profile_picture"
              onChange={handleFileChange}
              className="form-control-game"
              style={inputStyle} // Apply the input style
              accept=".jpg, .jpeg, .png, .gif, .heic" // Accept .heic and common image formats
            />
          </div>
          <button type="submit" className="btn-submit-game">
            Update Profile
          </button>
        </form>
      </div>
    </>
  );
}
