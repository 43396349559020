import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import './css/Leaderboard.css';
import Header from '../components/header';
import TodayDate from '../components/TodayDate';
import background from '../components/assets/background.png';

const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
});

//AXIOS VARIABLES ARE FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const carmeFontStyle = {
    fontFamily: "'Carme', sans-serif",
};

const Leaderboard = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Define isMobile here

    const [leaderboard, setLeaderboard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [nameFilter, setNameFilter] = useState('');
    const [rankFilter, setRankFilter] = useState('');
    const [graduationYearFilter, setGraduationYearFilter] = useState('');
    const [schoolFilter, setSchoolFilter] = useState('');
    const [currentUser, setCurrentUser] = useState(null);
    const itemsPerPage = 20;

    // CSRF Token
    const csrftoken = getCookie('csrftoken')

    const fetchLeaderboard = async () => {
        setLoading(true);
        try {
            const response = await client.get('/volunteer_hours_portfolio/leaderboard');
            setLeaderboard(response.data);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLeaderboard();
        const intervalId = setInterval(fetchLeaderboard, 1200000);

        return () => clearInterval(intervalId);
    }, [currentUser]);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const getUniqueValues = (key) => {
        return [...new Set(leaderboard.map(entry => entry[key]))];
    };

    const uniqueRanks = getUniqueValues('rank');
    const uniqueGraduationYears = getUniqueValues('user__graduation_year');
    const uniqueSchools = getUniqueValues('user__school');

    const filteredData = leaderboard.filter(entry => {
        const matchesName = entry.user__first_name.toLowerCase().includes(nameFilter.toLowerCase()) || entry.user__last_name.toLowerCase().includes(nameFilter.toLowerCase());
        const matchesRank = rankFilter === '' || entry.rank.toString() === rankFilter;
        const matchesGraduationYear = graduationYearFilter === '' || entry.user__graduation_year.toString() === graduationYearFilter;
        const matchesSchool = schoolFilter === '' || entry.user__school.toLowerCase().includes(schoolFilter.toLowerCase());

        return matchesName && matchesRank && matchesGraduationYear && matchesSchool;
    });

    const offset = currentPage * itemsPerPage;
    const currentPageData = filteredData.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading leaderboard: {error.message}</p>;

    // Adjust container and table styles based on screen size
    const containerStyle = {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: isMobile ? '40vh' : '60vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: isMobile ? 'center' : 'left',
        textAlign: isMobile ? 'center' : 'left',
        color: '#042c24',
        fontFamily: "'Anton', sans-serif",
        paddingLeft: isMobile ? '20px' : '135px',
        paddingBottom: '40px'
    };

    const tableStyles = {
        tableContainer: {
            width: isMobile ? '100%' : '90%',
            margin: isMobile ? '20px auto' : '50px auto',
            borderCollapse: 'collapse',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.8)',
        },
        tableWrapper: {
            overflowX: 'auto',  // Allows horizontal scrolling
            marginBottom: '20px',
        },
        table: {
            width: '100%',
            borderSpacing: '0',
            fontFamily: "'Anton', sans-serif",
            color: '#fff',
            minWidth: '600px',  // Ensures the table has a minimum width
        },
        headerRow: {
            backgroundColor: '#252624',
            textTransform: 'uppercase',
            color: '#fff',
            letterSpacing: '1px',
        },
        th: {
            padding: isMobile ? '8px' : '14px',
            textAlign: 'left',
            fontSize: isMobile ? '12px' : '16px',
            borderBottom: '3px solid #0a4b39',
        },
        row: {
            backgroundColor: '#1e1e1e',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        },
        rowHover: {
            backgroundColor: '#333333',
        },
        td: {
            padding: isMobile ? '8px' : '12px',
            textAlign: 'left',
            fontSize: isMobile ? '12px' : '16px',
            borderBottom: '3px solid #0a4b39',
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
        profilePicture: {
            width: isMobile ? '30px' : '50px',
            height: isMobile ? '30px' : '50px',
            borderRadius: '50%',
            objectFit: 'cover',
        },
    };

    const paginationStyles = {
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            listStyleType: 'none',
            padding: '10px',
            marginTop: '20px',
        },
        pageItem: {
            margin: '0 5px',
            cursor: 'pointer',
        },
        pageLink: {
            textDecoration: 'none',
            color: '#ffffff',
            padding: isMobile ? '5px 10px' : '10px 15px',
            border: '2px solid #042c24',
            borderRadius: '5px',
            backgroundColor: '#042c24',
            transition: 'background-color 0.3s, color 0.3s',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        },
        pageLinkHover: {
            backgroundColor: '#0a4b39',
            color: '#ffffff',
        },
        active: {
            backgroundColor: '#0a4b39',
            color: '#ffffff',
            border: '2px solid #042c24',
        },
    };

    return (
        <>
            <Header />
            <div style={containerStyle}>
                <div style={{ textAlign: 'left', width: '100%' }}>
                    <h1 style={{ fontSize: isMobile ? '25px' : '40px', textShadow: '2px 2px 5px #000', textAlign: 'left', fontWeight: 'bold', color: '#37bc8d' }}>
                        {TodayDate()}
                    </h1>
                </div>
                <h1 style={{ fontSize: isMobile ? '35px' : '80px', textShadow: '2px 2px 5px #000', textAlign: 'left', fontWeight: 'bold', color: '#d2da19' }}>VOLUNTEER HOUR LEADERBOARD</h1>
                <h1 style={{ fontSize: isMobile ? '16px' : '20px', textShadow: '2px 2px 5px #000', textAlign: 'left', fontWeight: 'bold', color: '#94c547' }}>VIEW THE TOP VOLUNTEERS ON TIMBERWOLVESERVICE HERE!</h1>
            </div>

            <div className="leaderboard" style={carmeFontStyle}>
                <div style={tableStyles.tableWrapper}>
                    <table style={tableStyles.tableContainer}>
                        <thead>
                            <tr style={tableStyles.headerRow}>
                                <th style={tableStyles.th} colSpan="6">TIMBERWOLVESERVICE LEADERBOARDS</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr style={tableStyles.headerRow}>
                                <th style={tableStyles.th} colSpan="2">
                                    <input
                                        type="text"
                                        placeholder="Filter by Name"
                                        value={nameFilter}
                                        onChange={e => setNameFilter(e.target.value)}
                                        style={{ marginRight: '10px', padding: '5px', width: isMobile ? '100%' : 'auto' }}
                                    />
                                </th>

                                <th style={tableStyles.th} colSpan="2">
                                    <select
                                        value={schoolFilter}
                                        onChange={e => setSchoolFilter(e.target.value)}
                                        style={{ padding: '5px', width: isMobile ? '100%' : 'auto' }}
                                    >
                                        <option value="">All Schools</option>
                                        {uniqueSchools.map(school => (
                                            <option key={school} value={school}>{school}</option>
                                        ))}
                                    </select>
                                </th>

                                <th style={tableStyles.th} colSpan="1">
                                    <select
                                        value={rankFilter}
                                        onChange={e => setRankFilter(e.target.value)}
                                        style={{ padding: '5px', width: isMobile ? '100%' : 'auto' }}
                                    >
                                        <option value="">All Ranks</option>
                                        {uniqueRanks.map(rank => (
                                            <option key={rank} value={rank}>{rank}</option>
                                        ))}
                                    </select>
                                </th>

                                <th style={tableStyles.th} colSpan="1">
                                    <select
                                        value={graduationYearFilter}
                                        onChange={e => setGraduationYearFilter(e.target.value)}
                                        style={{ padding: '5px', width: isMobile ? '100%' : 'auto' }}
                                    >
                                        <option value="">All Graduation Years</option>
                                        {uniqueGraduationYears.map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </th>
                            </tr>
                        </thead>

                        <thead>
                            <tr style={{ ...tableStyles.headerRow, backgroundColor: '#0a4b39' }}>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}>Rank</th>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}></th>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}>Name</th>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}>Rank</th>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}>Graduation Year</th>
                                <th style={{ ...tableStyles.th, color: '#c9e265' }}>Total Hours</th>
                            </tr>
                        </thead>

                        <tbody>
                            {currentPageData.map((entry, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        ...tableStyles.row,
                                        backgroundColor: index % 2 === 0 ? '#b1e3b9' : '#e6edca',
                                    }}
                                    onMouseOver={(e) => {
                                        e.currentTarget.style.backgroundColor = '#5cbf6d';
                                    }}
                                    onMouseOut={(e) => {
                                        e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#b1e3b9' : '#e6edca';
                                    }}
                                >
                                    <td style={tableStyles.td}>{entry.num_rank}</td>
                                    
                                    <td style={tableStyles.td}>
                                        {entry.user__profile_picture ? (
                                            <img 
                                                src={entry.user__profile_picture} 
                                                alt={`${entry.user__first_name} ${entry.user__last_name}'s Profile`} 
                                                style={tableStyles.profilePicture}
                                            />
                                        ) : (
                                            <span>No Image</span>
                                        )}
                                    </td>

                                    <td style={tableStyles.td}>{entry.user__first_name} {entry.user__last_name} <br></br> <div style={{fontSize: "11px"}}>{entry.user__school}</div></td>
                                    <td style={tableStyles.td}>{entry.rank}</td>
                                    <td style={tableStyles.td}>{entry.user__graduation_year}</td>
                                    <td style={tableStyles.td}>{entry.total_hours}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={isMobile ? 1 : 5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName={'page-link'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    activeLinkClassName={'active'}
                    breakLinkStyle={paginationStyles.break}
                    pageLinkStyle={paginationStyles.pageLink}
                    activeStyle={paginationStyles.active}
                />
            </div>
        </>
    );
};

export default Leaderboard;
