import './css/App.css';
import './css/Login.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../components/assets/logo.png';

// AXIOS VARIABLES ARE FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Client Instance with Django URL in order to type the url only once
const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const schoolOptions = [
  "Larchmont Charter High School",
  "UCLA"
];

const graduationyearOptions = [
  2022,
  2023,
  2024,
  2025,
  2026,
  2027,
  2028,
  2029,
  2030,
  2031,
  2032,
  2033
];

const brandStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

function Login() {
  const navigate = useNavigate();
  const handlePasswordResetClick = () => {
    navigate('/password-reset');
  };

  const csrftoken = getCookie('csrftoken');

  const [currentUser, setCurrentUser] = useState();
  const [registrationToggle, setRegistrationToggle] = useState(false);
  const [ErrorLogin, setErrorLogin] = useState(null);
  const [ErrorRegistration, setErrorRegistration] = useState(null);
  const [SuccessMessage, setSuccessMessage] = useState(null); // Add success message state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [school, setSchool] = useState('');
  const [graduationyear, setGraduationyear] = useState('');
  const[showPasswordResetMessageOldAccount, setshowPasswordResetMessageOldAccount] = useState('')
  const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(false);

  const montserratFontStyle = {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '24px',
    color: "#c9e265",
    textAlign: 'center',
    paddingLeft: '20px'
  };

  const navBarStyle = {
    backgroundColor: '#0a4b39',
    width: '100%',
    paddingTop: '0px',
    paddingBottom: '0px',
  };

  useEffect(() => {
    client.get("/accounts")
      .then(function (res) {
        setCurrentUser(true);
      })
      .catch(function (error) {
        setCurrentUser(false);
      });
  }, []);

  function update_form_btn() {
    if (registrationToggle) {
      document.getElementById("form_btn").innerHTML = "Register";
      setRegistrationToggle(false);
    } else {
      document.getElementById("form_btn").innerHTML = "Log in";
      setRegistrationToggle(true);
    }
  }

  function validateRegistration() {
    if (!email) {
      return "Users must have an email address";
    }
    if (!first_name) {
      return "Users must have a first name";
    }
    if (!last_name) {
      return "Users must have a last name";
    }
    if (!school) {
      return "Users must have a school name";
    }
    if (!graduationyear) {
      return "Users must have a graduation year";
    }
    return null;
  }

  function submitRegistration(e) {
    e.preventDefault();
  
    const validationError = validateRegistration();
    if (validationError) {
      setErrorRegistration(validationError);
      return;
    }
  
    client.post(
      "/accounts/register",
      {
        email: email,
        password: password,
        first_name: first_name,
        last_name: last_name,
        school: school,
        graduation_year: graduationyear,
      },
      {
        headers: {
          'X-CSRFToken': csrftoken
        }
      }
    ).then(function (res) {
      setSuccessMessage(res.data.detail); // Display success message (for both registration and resend verification)
    }).catch(function (error) {
      if (error.response) {
        if (error.response.data.detail === "Email already exists. Please log in.") {
          setErrorRegistration("Email already exists. Please log in.");
        } else if (password.length < 8) {
          setErrorRegistration("Password must be a minimum of 8 characters.");
        } else {
          setErrorRegistration("Registration failed. Please check your input.");
        }
      } else if (error.request) {
        setErrorRegistration("No response from server. Please try again later.");
      } else {
        setErrorRegistration("An unexpected error occurred. Please try again.");
      }
    });
  }
  

  function submitLogin(e) {
    e.preventDefault();
  
    // First, check if the account is old
    client.post("/accounts/check-old-account", { email: email },
      {
        headers: {
          'X-CSRFToken': csrftoken // Include the CSRF token in the request.
        }
      }
    )
    .then(function(res) {
        if (res.data.message === "This is an old account.") {
          // If it's an old account, trigger a password reset
          client.post("/accounts/password-reset", { email: email },
            {
              headers: {
                'X-CSRFToken': csrftoken
              }
            }
          )
          .then(function() {
            setshowPasswordResetMessageOldAccount(true);
          })
          .catch(function(error) {
            setErrorLogin("An error occurred while sending password reset email.");
          });
        } else {
          // Otherwise, attempt to log in
          client.post("/accounts/login", {
            email: email,
            password: password
          }, {
            headers: {
              'X-CSRFToken': csrftoken
            }
          }).then(function(res) {
            setCurrentUser(true);
            navigate('/opportunities'); // Adjust this if necessary for your desired navigation
          }).catch(function(ErrorLogin) {
            if (ErrorLogin.response) {
              if (ErrorLogin.response.status === 403) {
                setErrorLogin("Account not verified. Please check your email for verification.");
              } else {
                setErrorLogin("Invalid email or password.");
              }
            } else if (ErrorLogin.request) {
              setErrorLogin("No response from server.");
            } else {
              setErrorLogin("An unexpected error occurred.");
            }
          });
        }
    })
    .catch(function(error) {
        if (error.response) {
            setErrorLogin("Invalid email or password.");
        } else {
            setErrorLogin("An error occurred while checking the account status.");
        }
    });
  }
  
  if (showPasswordResetMessageOldAccount) {
    return (
      <div>
      <Navbar style={navBarStyle} data-bs-theme="dark" expand="lg">
        <Container className="header-container" style={montserratFontStyle}>
            <Navbar.Brand href="/" style={brandStyle}>
                <img
                    src={logo}
                    alt="logo"
                    width="55"
                    height="70"
                    className="d-inline-block align-top"
                />{' '}
                <span style={montserratFontStyle}><b>TimberwolveService</b></span>
            </Navbar.Brand>
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  <Button className="login-form-btn" id="form_btn" onClick={update_form_btn} variant="light">Register</Button>
                </Navbar.Text>
              </Navbar.Collapse>
        </Container>
    </Navbar>
        <div>
          <Container>
            It appears that this account was registered a while back. In order to log back into your account, please complete the password reset process that has been sent in an email to <b>{email}</b>. If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder.
          </Container>
        </div>
      </div>
    );
  }

  if (showPasswordResetMessage) {
    return (
      <div>
        <Navbar style={navBarStyle} expand="lg">
          <Container className="header-container" style={montserratFontStyle}>
            <Navbar.Brand href="/" style={brandStyle}>
              <img
                src={logo}
                alt="logo"
                width="55"
                height="70"
                className="d-inline-block align-top"
              />{' '}
              <span style={montserratFontStyle}><b>TimberwolveService</b></span>
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <Button className="login-form-btn" id="form_btn" onClick={update_form_btn} variant="light">Register</Button>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>
          A password reset email has been sent to <b>{email}</b>.
        </Container>
      </div>
    );
  }

  if (currentUser) {
    navigate('/opportunities');
  }

  return (
    <div>
      <Navbar style={navBarStyle} expand="lg">
        <Container className="header-container" style={montserratFontStyle}>
          <Navbar.Brand href="/" style={brandStyle}>
            <img
              src={logo}
              alt="logo"
              width="55"
              height="70"
              className="d-inline-block align-top"
            />{' '}
            <span style={montserratFontStyle}><b>TimberwolveService</b></span>
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <Button className="login-form-btn" id="form_btn" onClick={update_form_btn} variant="light">Register</Button>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {registrationToggle ? (
        <div className="form-container">
          <h1 className="form-title">Register</h1>
          <Form onSubmit={e => submitRegistration(e)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" className="form-field" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" className="form-field" placeholder="Enter First Name" value={first_name} onChange={e => setFirst_name(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" className="form-field" placeholder="Enter Last Name" value={last_name} onChange={e => setLast_name(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicSchool">
              <Form.Label>School</Form.Label>
              <Form.Select className="form-field" value={school} onChange={e => setSchool(e.target.value)}>
                <option value="">Select your school</option>
                {schoolOptions.map((schoolOption) => (
                  <option key={schoolOption} value={schoolOption}>{schoolOption}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicYear">
              <Form.Label>Graduation Year</Form.Label>
              <Form.Select className="form-field" value={graduationyear} onChange={e => setGraduationyear(e.target.value)}>
                <option value="">Select your graduation year</option>
                {graduationyearOptions.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" className="form-field" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
            </Form.Group>

            {ErrorRegistration && <p style={{ color: 'red' }}>{ErrorRegistration}</p>}
            {SuccessMessage && <p style={{ color: 'green' }}>{SuccessMessage}</p>} {/* Display success message */}

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
          <p></p>
          <button onClick={update_form_btn} style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', marginLeft: '-4%', cursor: 'pointer' }}>
            Already have an account? Login here
          </button>
        </div>
      ) : (
        <div className="center form-container">
          <h1 className="form-title">Log In</h1>
          <Form onSubmit={e => submitLogin(e)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" className="form-field" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" className="form-field" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
            </Form.Group>

            {ErrorLogin && <p style={{ color: 'red' }}>{ErrorLogin}</p>}

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
          <p></p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <button onClick={update_form_btn} style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer', marginLeft: '-4%' }}>
              Don't have an account? Register here
            </button>
            <button onClick={handlePasswordResetClick} style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer', padding: 0 }}>
              Don't Remember Password? Click Here to Reset Password
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
