import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './assets/logo.png'; 
import '../pages/css/Header.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { useMediaQuery } from 'react-responsive';

const linktext = {
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '18px',
  color: "#c9e265",
  textAlign: 'center',
  paddingLeft: '20px',
};

const navBarStyle = {
    backgroundColor: '#0a4b39',
    width: '100%',
    paddingTop: '0px',
    paddingBottom: '0px',
};

const brandStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const Header = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const csrftoken = getCookie('csrftoken');
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // State for admin status
  const [profilePicture, setProfilePicture] = useState(null);
  const navigate = useNavigate();
  const[isAdminHead, setIsAdminHead] = useState(false);
  const montserratFontStyle = {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: isMobile ? '16px' : '24px',  // Adjust font size for mobile
    color: "#c9e265",
    textAlign: 'center',
    paddingLeft: '20px'
  };

  const handleLoginRedirect = (e) => {
    e.preventDefault();
    navigate('/login');
  };

  const handleLeaderboardRedirect = (e) => {
    e.preventDefault();
    navigate('/leaderboard');
  };

  const handleLoginAbout = (e) => {
    e.preventDefault();
    navigate('/about');
  };

  useEffect(() => {
    client.get("/accounts/check-admin-status", {
      headers: { 'X-CSRFToken': csrftoken },
    })
      .then((res) => {
        // Check if the user has admin privileges
        if (res.data.is_school_administration_head) {
          setIsAdminHead(true); // User is a School Administration Head
        }
      })
      .catch(() => {
        setIsAdminHead(false);
      });
  }, [csrftoken]);
  
  // Fetch admin status
  useEffect(() => {
    client.get("/accounts/check-admin-status", {
      headers: { 'X-CSRFToken': csrftoken },
    })
      .then((res) => {
        // Check if the user has admin privileges
        if (res.data.is_school_administration || res.data.is_school_administration_head) {
          setIsAdmin(true);
        }
      })
      .catch(() => {
        setIsAdmin(false);
      });
  }, [csrftoken]);

  // Check if user is logged in
  useEffect(() => {
    client.get("/accounts", {
      headers: { 'X-CSRFToken': csrftoken },
    })
      .then((res) => {
        setCurrentUser(res.data);
      })
      .catch(() => {
        setCurrentUser(false);
      });
  }, [csrftoken]);

  // Fetch profile picture if user is logged in
  useEffect(() => {
    if (currentUser) {
      client.get("/accounts/profile", {
        headers: { 'X-CSRFToken': csrftoken },
      })
        .then((res) => {
          setProfilePicture(res.data.profile_picture);
        })
        .catch(() => {
          setProfilePicture(null);
        });
    }
  }, [currentUser, csrftoken]);

  const submitLogout = (e) => {
    e.preventDefault();
    client.post("/accounts/logout", {}, {
      headers: { 'X-CSRFToken': csrftoken },
    }).then(() => {
      navigate('/');
      setCurrentUser(false);
    });
  };

  return (
    <Navbar style={navBarStyle} data-bs-theme="dark" expand="lg">
      <Container className="header-container" style={montserratFontStyle}>
        <Navbar.Brand href="/" style={brandStyle}>
          <img
            src={logo}
            alt="logo"
            width={isMobile ? '40' : '55'}
            height={isMobile ? '50' : '70'}
            className="d-inline-block align-top"
          />{' '}
          <span style={montserratFontStyle}><b>TimberwolveService</b></span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          {currentUser ? (
            <Nav className={isMobile ? "me-auto" : "ms-auto"} style={linktext}>
              <NavDropdown title="Opportunities" id="events-dropdown" style={linktext}>
                <NavDropdown.Item href="/opportunities">Volunteer Organizations</NavDropdown.Item>
                <NavDropdown.Item href="/event-list">Community Events (Beta)</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/post-event">Post Events (Beta)</NavDropdown.Item>
                <NavDropdown.Item href="/your-events">Your Events (Beta)</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={isAdmin || isAdminHead ? "Administration Data" : "Portfolio"} id="portfolio-dropdown" style={linktext}>
                {isAdmin || isAdminHead ? (
                  // Empty dropdown for administrators
                  <>
                    <NavDropdown.Item href="/admin/entries">Volunteer Database</NavDropdown.Item>
                    <NavDropdown.Item href="/admin/user-entries/recycling-bin">Recover Entries</NavDropdown.Item>
                    {isAdminHead && (
                    <NavDropdown.Item href="/admin/network">Administration Network</NavDropdown.Item>
                    )}
                  </>
                  
                ) : (
                  <>
                    <NavDropdown.Item href="/hour-tracker">Your Portfolio</NavDropdown.Item>
                    <NavDropdown.Item href="/portfolio-create-event">Record Hours</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/leaderboard">Leaderboard</NavDropdown.Item>
                  </>
                )}
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                    {profilePicture && (
                      <img
                        src={profilePicture}
                        alt="Profile"
                        style={{
                          width: isMobile ? '30px' : '40px',
                          height: isMobile ? '30px' : '40px',
                          borderRadius: '50%',
                          marginRight: '10px',
                          objectFit: 'cover',
                        }}
                      />
                    )}
                    Profile
                  </>
                }
                id="profile-dropdown"
                align={isMobile ? "start" : "end"}
                style={linktext}
              >
                <NavDropdown.Item href="/profile">View Profile</NavDropdown.Item>
                <NavDropdown.Item href="/profile/edit">Edit Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <form onSubmit={submitLogout} style={{ margin: 0 }}>
                  <button 
                    type="submit" 
                    className="dropdown-item" 
                    style={{ 
                      border: 'none', 
                      background: 'none', 
                      padding: '8px 16px', 
                      width: '100%', 
                      textAlign: 'left', 
                      display: 'block',
                    }}
                  >
                    Logout
                  </button>
                </form>
              </NavDropdown>
            </Nav>
          ) : (
            <Nav className="ms-auto" style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
              <button 
                onClick={handleLeaderboardRedirect} 
                className="dropdown-item" 
                style={{ 
                  border: 'none', 
                  background: 'none', 
                  display: 'inline-flex',
                  alignItems: 'center',
                  color: '#b0d5cd', 
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: isMobile ? '14px' : '18px',
                  padding: '8px 16px',
                }}
              >
                Leaderboard
              </button>

              <button 
                onClick={handleLoginAbout} 
                className="dropdown-item" 
                style={{ 
                  border: 'none', 
                  background: 'none', 
                  display: 'inline-flex',
                  alignItems: 'center',
                  color: '#b0d5cd',  
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: isMobile ? '14px' : '18px',
                  padding: '8px 16px',
                }}
              >
                About
              </button>
              
              <button 
                onClick={handleLoginRedirect} 
                className="dropdown-item" 
                style={{ 
                  border: 'none', 
                  background: 'none', 
                  display: 'inline-flex',
                  alignItems: 'center',
                  color: '#b0d5cd',  
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: isMobile ? '14px' : '18px',
                  padding: '8px 16px',
                }}
              >
                Login
              </button>
              
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
