import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import NoPage from './pages/NoPage';
import Login from './pages/Login';
import Portfolio from './pages/Portfolio';
import PortfolioForm_Create from './pages/PortfolioForm_Create';
import PortfolioForm_Update from './pages/PortfolioForm_Update';
import UserProfile from './pages/UserProfile';
import EventList from './pages/EventList';
import PostEvent from './pages/PostEvent';
import EventDetail from './pages/EventDetail';
import './pages/css/App.css';  
import PasswordResetConfirm from './pages/PasswordResetConfirm';
import Leaderboard from './pages/Leaderboard';
import Main_Account_Profile from './pages/Main_Account_Profile';
import Edit_Main_Account_Profile from './pages/Edit_Main_Account_Profile';
import PasswordResetConfirmation from './pages/PasswordResetConfirmation_Message';
import VerificationPage from './pages/VerificationPage';
import Opportunities from './pages/Opportunities';
import PasswordReset from './pages/PasswordReset';
import VerifyEmail from './pages/VerifyEmail';
import AboutVerification from './pages/AboutVerification.js';
import ReleaseNotes from './pages/ReleaseNotes.js';
import Admin_Entries from './pages/admin/Entries_Summary.js';
import Unauthorized from './pages/Unauthorized.js';
import AdminEntryDetail from './pages/admin/Entries_Detail.js';
import AdminUpdateEntry from './pages/admin/Update_Entry.js';
import AdminUserManager from './pages/admin/AdminUserManager.js';
import RecyclingBin from './pages/admin/RecyclingBin.js';

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});


// Private route component for School Administration Head-only pages
const AdminHeadRoute = ({ element: Element }) => {
  const [isAdminHead, setIsAdminHead] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the current user is a school administration head
    client.get('/accounts/check-admin-status')
      .then((res) => {
        if (res.data.is_school_administration_head) {
          setIsAdminHead(true); // User is a School Administration Head
        } else {
          setIsAdminHead(false); // User is not a School Administration Head
          navigate('/unauthorized');
        }
      })
      .catch(() => {
        setIsAdminHead(false);
        navigate('/unauthorized');
      });
  }, [navigate]);

  // Wait for the admin head status to be determined
  if (isAdminHead === null) return <div>Loading...</div>;
  return isAdminHead ? <Element /> : null;
};


// Private route component for admin-only pages
const AdminRoute = ({ element: Element }) => {
  const [isAdmin, setIsAdmin] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    // Check if the current user is an admin
    client.get('/accounts/check-admin-status')
      .then((res) => {
        if (res.data.is_school_administration || res.data.is_school_administration_head) {
          setIsAdmin(true); // User is an admin
        } else {
          setIsAdmin(false); // User is not an admin
          navigate('/unauthorized');
        }
      })
      .catch(() => {
        setIsAdmin(false);
        navigate('/unauthorized');
      });
  }, [navigate]);


  // Wait for the admin status to be determined
  if (isAdmin === null) return <div>Loading...</div>;

  return isAdmin ? <Element /> : null;
};

export default function App() {
  return (
        <div>
          <BrowserRouter>
            <div style={styles.container}>
              <Routes>
                {/* Other routes... */}
                <Route path="/" index element={<Home />} />
                <Route path="/contact" index element={<Contact />} />
                <Route path="/about" index element={<About />} />
                <Route path="/login" index element={<Login />} />
                <Route path="/hour-tracker" index element={<Portfolio />} />
                <Route path="/portfolio-create-event" index element={<PortfolioForm_Create />} />
                <Route path="/portfolio-update-event/:pk" index element={<PortfolioForm_Update />} />
                <Route path="/event-list" index element={<EventList />} />
                <Route path="/event-detail/:eventId" index element={<EventDetail />} />
                <Route path="/your-events" index element={<UserProfile />} />
                <Route path="/post-event" index element={<PostEvent />} />
                <Route path="*" index element={<NoPage />} />
                <Route path="/password-reset-confirm/:uid/:token" index element={<PasswordResetConfirm />} />
                <Route path="/leaderboard" index element={<Leaderboard />} />
                <Route path="/profile" index element={<Main_Account_Profile />} />
                <Route path="/profile/edit" index element={<Edit_Main_Account_Profile />} />
                <Route path="/password-reset-confirmation-message" index element={<PasswordResetConfirmation />} />
                <Route path="/verify-entry/:token" element={<VerificationPage />} />
                <Route path="/opportunities" element={<Opportunities />} />
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route path="/verify-email/:token" element={<VerifyEmail />} />
                <Route path="/about-verification" element={<AboutVerification />} />
                <Route path="/release-notes" element={<ReleaseNotes />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
    
                {/* ADMINISTRATION PAGES */}
                <Route path="/admin/entries" element={<AdminRoute element={Admin_Entries} />} />
                <Route path="/admin/user-entries/:userId" element={<AdminRoute element={AdminEntryDetail} />} />
                <Route path="/admin/user-entries/update/:pk" element={<AdminRoute element={AdminUpdateEntry} />} />
                <Route path="/admin/user-entries/recycling-bin" element={<AdminRoute element={RecyclingBin} />} />

                {/* ADMIN HEAD-ONLY PAGE */}
                <Route path="/admin/network" element={<AdminHeadRoute element={AdminUserManager} />} />

              </Routes>
            </div>
          </BrowserRouter>
        </div>
    
  );
}

const styles = {
  container: {
    fontFamily: 'Helvetica, Arial, sans-serif',
  },
};
