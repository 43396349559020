// src/pages/PasswordResetConfirmation.js

import React from 'react';
import Header from '../components/header';
import './css/PasswordResetConfirmation.css'; // Create and import a CSS file for styling

export default function PasswordResetConfirmation() {
  return (
    <>
      <Header />
      <div className="confirmation-container">
        <h2>Password Reset</h2>
        <p>Password reset instructions have been sent to your email.</p>
      </div>
    </>
  );
}
