import axios from 'axios';

// Helper function to get the CSRF token from cookies
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

// Create an Axios instance with default settings
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,  // Your base API URL
    timeout: 5000,  // Request timeout limit
    headers: {
        'Content-Type': 'application/json',  // Default content type for all requests
    },
    withCredentials: true,  // Send cookies and CSRF token with requests
});

// Axios request interceptor to add CSRF token
axiosInstance.interceptors.request.use(
    config => {
        const csrftoken = getCookie('csrftoken');  // Get the CSRF token from cookies
        if (csrftoken) {
            config.headers['X-CSRFToken'] = csrftoken;  // Attach the CSRF token to the request headers
        }
        return config;
    },
    error => {
        return Promise.reject(error);  // Handle request errors
    }
);

export default axiosInstance;