import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './css/VerificationPage.css'; // Import the CSS file for styling
import Header from '../components/header';

// AXIOS VARIABLES ARE FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Client Instance with Django URL in order to type the URL only once
const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
});

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const VerificationPage = () => {
    const { token } = useParams(); // Get the token from the URL
    const [verificationStatus, setVerificationStatus] = useState('verifying'); // States: verifying, success, error
    const [entryDetails, setEntryDetails] = useState(null); // State to hold the entry details
    const [actionType, setActionType] = useState(null); // State to track whether accept or reject was chosen
    const navigate = useNavigate();

    useEffect(() => {
        // Function to verify the token and fetch entry details
        const verifyToken = async () => {
            try {
                const response = await client.post(
                    '/volunteer_hours_portfolio/verify-entry',
                    { token }, // Pass the token in the request body
                    {
                        headers: {
                            'X-CSRFToken': getCookie('csrftoken'),
                        }
                    }
                );
                if (response.status === 200) {
                    setEntryDetails(response.data.entry); // Set the entry details
                    setVerificationStatus('success');
                } else {
                    setVerificationStatus('error');
                }
            } catch (error) {
                console.error('Verification failed:', error);
                setVerificationStatus('error');
            }
        };

        verifyToken();
    }, [token]); // Add token to dependency array

    const handleAction = async (action) => {
        try {
            const response = await client.post(
                '/volunteer_hours_portfolio/verify-entry-action',
                { token, action }, // Pass the token and action in the request body
                {
                    headers: {
                        'X-CSRFToken': getCookie('csrftoken'),
                    }
                }
            );

            if (response.status === 200) {
                setVerificationStatus('action-success');
                setActionType(action); // Set the action type (accept or reject)
            } else {
                setVerificationStatus('error');
            }
        } catch (error) {
            console.error('Action failed:', error);
            setVerificationStatus('error');
        }
    };

    const handleRedirect = () => {
        navigate('/'); // Redirect to the home page or another route
    };

    return (
        <>
        <Header />
        <div className="verification-container">
            {verificationStatus === 'verifying' && (
                <p>Verifying your entry, please wait...</p>
            )}
            {verificationStatus === 'success' && entryDetails && (
                <div>
                    <h2>Entry Verification</h2>
                    <p><strong>Event:</strong> {entryDetails.event}</p>
                    <p><strong>Date:</strong> {entryDetails.date}</p>
                    <p><strong>Hours:</strong> {entryDetails.hours}</p>
                    <p><strong>Role:</strong> {entryDetails.role}</p>
                    <p><strong>Organizer:</strong> {entryDetails.organizer}</p>
                    <p><strong>Description:</strong> {entryDetails.description}</p>
                    <p>Please choose an action:</p>
                    <button onClick={() => handleAction('accept')} className="accept-button">
                        Accept
                    </button>
                    <button onClick={() => handleAction('reject')} className="reject-button">
                        Reject
                    </button>
                </div>
            )}
            {verificationStatus === 'action-success' && (
                <div>
                    <h2>Action Completed Successfully!</h2>
                    <p>The entry has been successfully {actionType === 'accept' ? 'accepted' : 'rejected'}.</p> {/* Use actionType instead of verificationStatus */}
                    <button onClick={handleRedirect} className="redirect-button">
                        Go to Home
                    </button>
                </div>
            )}
            {verificationStatus === 'error' && (
                <div>
                    <h2>Action Failed</h2>
                    <p>There was an error processing your action. Please try again or contact support.</p>
                    <button onClick={handleRedirect} className="redirect-button">
                        Go to Home
                    </button>
                </div>
            )}
        </div>
        </>
    );
};

export default VerificationPage;
