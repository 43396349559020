import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header'; // Assuming you have a Header component
import './css/Login.css'; // Reuse the CSS styles from the login page

// AXIOS VARIABLES FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate('/login'); // This will navigate to the PasswordReset component
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    const csrftoken = getCookie('csrftoken');

    client.post('/accounts/password-reset', 
      { email: email.toLowerCase() }, 
      { headers: { 'X-CSRFToken': csrftoken } }
    )
    .then(response => {
      setSuccessMessage('Password reset link has been sent to your email.');
      setErrorMessage('');
    })
    .catch(error => {
      setErrorMessage('Failed to send password reset link. Please try again.');
      setSuccessMessage('');
    });
  };

  
  return (
    <>
      <Header /> {/* Include the header for consistency */}
      <div className="form-container">
        <h1 className="form-title">Password Reset</h1>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handlePasswordReset} className="password-reset-form">
          <div className="form-group">
            <label htmlFor="email">Enter your email address:</label>
            <input 
              type="email" 
              id="email" 
              className="form-field" 
              placeholder="Enter email"
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        <br></br>
          <button onClick={handleLogin} style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', marginLeft: '-4%', cursor: 'pointer' }}>
              Already have an account? Login here
          </button>

        </form>
      </div>
    </>
  );
};

export default PasswordReset;
