import Header from '../components/header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function About() {
    // Style object for the About page
    const aboutPageStyle = {
        backgroundColor: '#ffffff', // White background
        color: '#000000',           // Black text
        padding: '2rem',
        textAlign: 'center',
        fontFamily: "'Montserrat', sans-serif",
    };

    // Style for headings
    const headingStyle = {
        fontWeight: 'bold',
        color: '#0a4b39', // Dark green (same as header)
    };

    // Style for section margins
    const sectionStyle = {
        margin: '3rem 0',
    };

    return (
        <>
            <Header />
            <Container style={aboutPageStyle}>
                
                <section style={sectionStyle}>
                    <h2 style={headingStyle}>Mission and Vision of TimberwolveService</h2>
                    <h4><i>"Inspiring Community!"</i></h4>
                    <p>
                        TimberwolveService is a community service and development platform. Our mission is to inspire 
                        every student and person to positively make an impact in their community through
                        service. Our choices are guided towards three objectives: tying the service community together, 
                        inspiring a sense of community in every student, and increasing service hours per student.
                    </p>
                </section>

                <section style={sectionStyle}>
                    <h2 style={headingStyle}>Values of TimberwolveService</h2>
                    <h4><i>"Building The Future For Community Service"</i></h4>
                    <p>
                        As a team of problem-solvers, we believe it is always best to look for solutions through the lens of first principles. 
                        Timberwolveservice's first principles were discovered by answering the following three questions: What are we deeply passionate about? 
                        What can we be the best at? What primary parameter are we trying to optimize? Applying these three questions to TimberwolveService, 
                        our team believes that we are passionate about inspiring a sense of community in every school, we seek to be the best at tying the service community 
                        together, and we are driven to increase the service hours per student. These three principles make up the core concept of TimberwolveService.
                    </p>
                </section>

                <section style={sectionStyle}>
                    <h2 style={headingStyle}>History of TimberwolveService</h2>
                    <p>
                        TimberwolveService was founded by Marc Luzuriaga in 2020 when he was in high school at Larchmont Charter High School. 
                        The web platform started as a community service resource list; however, after discovering the surmounting problems facing community service at Larchmont, he sought to grow 
                        Timberwolveservice to a platform that can solve these problems. Today, Marc Luzuriaga is currently a junior double-majoring in Computer Science and Business Economics
                        at the University of California, Los Angeles (UCLA).
                    </p>
                </section>

                {/* Team section can be uncommented and modified if needed */}
                {/* <section style={sectionStyle}>
                    <Row>
                        <Col md={4}>
                            <img
                                src="path/to/team-member1.jpg"
                                alt="Team Member 1"
                                style={{ width: '100%', borderRadius: '50%' }}
                            />
                            <h4>John Doe</h4>
                            <p>Founder & CEO</p>
                        </Col>
                        <Col md={4}>
                            <img
                                src="path/to/team-member2.jpg"
                                alt="Team Member 2"
                                style={{ width: '100%', borderRadius: '50%' }}
                            />
                            <h4>Jane Smith</h4>
                            <p>COO</p>
                        </Col>
                        <Col md={4}>
                            <img
                                src="path/to/team-member3.jpg"
                                alt="Team Member 3"
                                style={{ width: '100%', borderRadius: '50%' }}
                            />
                            <h4>Alex Johnson</h4>
                            <p>CTO</p>
                        </Col>
                    </Row>
                </section> */}

                <section style={sectionStyle}>
                    <h2 style={headingStyle}>Contact Us</h2>
                    <p>
                        We’d love to hear from you! If you have any questions, feedback, or partnership
                        opportunities, feel free to get in touch with us.
                    </p>
                    <p>
                        Email: <a href="mailto:TimberwolveService@gmail.com" style={{ color: '#0a4b39' }}>TimberwolveService@gmail.com</a>
                    </p>
                </section>
            </Container>
        </>
    );
}
