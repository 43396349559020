import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Header from '../components/header';
import './css/Main_Account_Profile.css'; // Import the CSS file

// AXIOS VARIABLES FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

export default function Main_Account_Profile() {
  const csrftoken = getCookie('csrftoken');
  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    school: '',
    graduation_year: '',
    profile_picture: '',
    email: '',
  });
  const [currentUser, setCurrentUser] = useState(null); // State to track authentication
  const navigate = useNavigate(); // Initialize useNavigate

  // Check if user is authenticated, if not redirect to login
  useEffect(() => {
    client.get("/accounts")
      .then(() => setCurrentUser(true))
      .catch(() => {
        setCurrentUser(false);
        navigate('/login');
      });
  }, [navigate]);

  useEffect(() => {
    if (currentUser) {
      client.get('/accounts/profile', {
        headers: {
          'X-CSRFToken': csrftoken
        }
      })
      .then(response => {
        setProfile(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the profile data!', error);
      });
    }
  }, [csrftoken, currentUser]);

  // Function to handle the redirection to edit profile
  const handleEditProfile = () => {
    navigate('/profile/edit'); // Redirect to /profile/edit
  };

  // Function to handle password reset
  const handleResetPassword = () => {
    client.post('/accounts/password-reset', { email: profile.email }, { // Use profile.email
      headers: {
        'X-CSRFToken': csrftoken
      }
    })
    .then(response => {
      navigate('/password-reset-confirmation-message'); // Redirect to confirmation page
    })
    .catch(error => {
      console.error('There was an error resetting the password!', error);
      alert('Failed to reset password.');
    });
  };

  return (
    <>
      <Header />
      <div className="profile-container-game">
        <h2 className="profile-title">Account Profile</h2>
        <div className="profile-content-game">
          <img src={profile.profile_picture} alt="Profile" className="profile-picture-game" />
          <p className="profile-detail-game">First Name: {profile.first_name} </p>
          <p className="profile-detail-game">Last Name: {profile.last_name}</p>
          <p className="profile-detail-game">School: {profile.school}</p>
          <p className="profile-detail-game">Graduation Year: {profile.graduation_year}</p>
          <br></br>
          <button onClick={handleEditProfile}>Edit Personal Information</button>
          <br></br>
          <button onClick={handleResetPassword}>Reset Password</button>
        </div>
      </div>
    </>
  );
}
