import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoginButton from '../components/login_button'

//AXIOS VARIABLES ARE FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Function to get the CSRF token from the cookie. Function in Django Documentation
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
  });
  
  const PasswordResetConfirm = () => {
    const csrftoken = getCookie('csrftoken');
    const { uid, token } = useParams(); // Get uid and token from URL parameters
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false); // State to handle success message
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (newPassword !== newPasswordConfirm) {
        setError('Passwords do not match');
        return;
      }
  
      try {
        const response = await client.post(`/accounts/password-reset-confirm/${uid}/${token}/`, {
          new_password: newPassword,
          new_password_confirm: newPasswordConfirm,
        },
        {
          headers: {
              'X-CSRFToken': csrftoken // Include the CSRF token in the request
          }
        }
      );
  
        setMessage(response.data.detail);
        setError('');
        setSuccess(true); // Set success to true on successful password reset
      } catch (error) {
        setError(error.response.data.detail || 'An error occurred');
        setMessage('');
        setSuccess(false); // Ensure success is false if there's an error
      }
    };
  
  
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1>Reset Password</h1>
        {success ? (
          <div>
            <p style={{ color: 'green' }}>{message}</p>
            <LoginButton />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            {message && <p style={{ color: 'green' }}>{message}</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div>
              <label htmlFor="new_password">New Password</label>
              <input
                type="password"
                id="new_password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="new_password_confirm">Confirm New Password</label>
              <input
                type="password"
                id="new_password_confirm"
                value={newPasswordConfirm}
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                required
              />
            </div>
            <button type="submit">Reset Password</button>
          </form>
        )}
      </div>
    );
  };
  
  export default PasswordResetConfirm;