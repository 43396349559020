import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import '../css/AdminUserManager.css';  // Import the CSS file
import Header from '../../components/header';
// AXIOS setup for security purposes
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const AdminUserManager = () => {
    const csrftoken = getCookie('csrftoken');
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [warningMessage, setWarningMessage] = useState("");
    const [actionType, setActionType] = useState(""); // "add" or "remove"
    const [showAdminOnly, setShowAdminOnly] = useState("");

    // Pagination state
    const [currentPage, setCurrentPage] = useState(0);
    const usersPerPage = 30;

    // Search state
    const [searchFirstName, setSearchFirstName] = useState('');
    const [searchLastName, setSearchLastName] = useState('');
    const [searchEmail, setSearchEmail] = useState('');

    useEffect(() => {
        client.get('/admin/network/users')
            .then(response => {
                setUsers(response.data);
                setFilteredUsers(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("There was an error fetching the users!", error);
                setIsLoading(false);
            });
    }, []);

    const handleSelectUser = (userId) => {
        if (selectedUsers.includes(userId)) {
            setSelectedUsers(selectedUsers.filter(id => id !== userId));
        } else {
            setSelectedUsers([...selectedUsers, userId]);
        }
    };

    const handleConfirmAction = () => {
        let promises = selectedUsers.map(userId => {
            return client.post(`/admin/network/user/${userId}`, { action: actionType }, {
                headers: { 
                    'X-CSRFToken': csrftoken,
                    'Content-Type': 'application/json'  // Ensure content-type is set
                }
            })
            .then(response => {
                // Update the admin status in the user list based on the action
                const updatedUsers = users.map(user =>
                    user.id === response.data.id ? { ...user, is_school_admin: response.data.is_school_admin } : user
                );
                setUsers(updatedUsers);
                setFilteredUsers(updatedUsers); // Update the filtered list too
            })
            .catch(error => {
                console.error("There was an error updating the user!", error);
            });
        });
    
        // Wait for all requests to complete before refreshing
        Promise.all(promises)
            .then(() => {
                setWarningMessage("");
                setSelectedUsers([]);
                // Refresh the page
                window.location.reload();
            });
    };
    

    const handleActionClick = (type) => {
        // Create a string of selected users' names or emails
        const selectedUserDetails = selectedUsers.map(userId => {
            const user = users.find(user => user.id === userId);
            return `${user.first_name} ${user.last_name} (${user.email})`;
        }).join(', ');
    
        setActionType(type);
        setWarningMessage(`Are you sure you want to ${type === 'add' ? 'add' : 'remove'} the following users as admin: ${selectedUserDetails}?`);
    };
    
    const handleCancel = () => {
        setWarningMessage("");
        setSelectedUsers([]);
    };

    // Filter by admin status
    const handleAdminFilterChange = () => {
        setShowAdminOnly(!showAdminOnly);
        if (!showAdminOnly) {
            setFilteredUsers(users.filter(user => user.is_school_administration));
        } else {
            setFilteredUsers(users);
        }
        setCurrentPage(0); // Reset to the first page
    };

    // Pagination logic
    const pageCount = Math.ceil(filteredUsers.length / usersPerPage);
    const offset = currentPage * usersPerPage;
    const currentUsers = filteredUsers.slice(offset, offset + usersPerPage);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    // Search logic
    const handleSearch = () => {
        const filtered = users.filter(user =>
            user.first_name.toLowerCase().includes(searchFirstName.toLowerCase()) &&
            user.last_name.toLowerCase().includes(searchLastName.toLowerCase()) &&
            user.email.toLowerCase().includes(searchEmail.toLowerCase())
        );
        setFilteredUsers(filtered);
        setCurrentPage(0); // Reset to the first page after search
    };

    if (isLoading) {
        return <div>Loading users...</div>;
    }

    return (
        <>
            <Header></Header>
            <div className="admin-user-manager">
                <h2 className="title">Manage Admin Users</h2>

                {/* Search fields */}
                <div className="search-fields">
                    <input
                        type="text"
                        placeholder="Search by First Name"
                        value={searchFirstName}
                        onChange={(e) => setSearchFirstName(e.target.value)}
                        className="search-input"
                    />
                    <input
                        type="text"
                        placeholder="Search by Last Name"
                        value={searchLastName}
                        onChange={(e) => setSearchLastName(e.target.value)}
                        className="search-input"
                    />
                    <input
                        type="text"
                        placeholder="Search by Email"
                        value={searchEmail}
                        onChange={(e) => setSearchEmail(e.target.value)}
                        className="search-input"
                    />
                    <button onClick={handleSearch} className="search-button">Search</button>
                </div>

                {/* Admin Filter */}
                <div className="admin-filter">
                    <label>
                        <input
                            type="checkbox"
                            checked={showAdminOnly}
                            onChange={handleAdminFilterChange}

                        />
                        Show Admins Only
                    </label>
                </div>

                {/* Users Table */}
                <table className="users-table">
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Admin Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentUsers.map(user => (
                            <tr key={user.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedUsers.includes(user.id)}
                                        onChange={() => handleSelectUser(user.id)}
                                    />
                                </td>
                                <td>{user.first_name}</td>
                                <td>{user.last_name}</td>
                                <td>{user.email}</td>
                                <td className="admin-status">
                                    {user.is_school_administration ? (
                                        <span className="checkmark">✓</span>
                                    ) : (
                                        <span className="xmark">x</span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Pagination */}
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    previousClassName={'pagination-previous'}
                    nextClassName={'pagination-next'}
                />

                {selectedUsers.length > 0 && (
                    <div className="action-buttons">
                        <button onClick={() => handleActionClick('add')} className="action-button add-button">Add to Admin</button>
                        <button onClick={() => handleActionClick('remove')} className="action-button remove-button">Remove from Admin</button>
                    </div>
                )}

                {warningMessage && (
                    <div className="warning-message">
                        <p>{warningMessage}</p>
                        <button onClick={handleConfirmAction} className="confirm-button">Confirm</button>
                        <button onClick={handleCancel} className="cancel-button">Cancel</button>
                    </div>
                )}
            </div>
        </>
    );
};

export default AdminUserManager;
