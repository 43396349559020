import Header from '../../components/header';
import '../css/App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';

// AXIOS VARIABLES FOR SECURITY PURPOSES
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Client Instance with Django URL in order to type the URL only once
const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

// Function to get the CSRF token from the cookie
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export default function AdminUpdateEntry() {
  const csrftoken = getCookie('csrftoken');

  const { pk } = useParams(); // Get the entry ID from the URL
  const navigate = useNavigate();
  const [ErrorUpdate, setErrorUpdate] = useState(null);

  const [event, setEvent] = useState('');
  const [date, setDate] = useState('');
  const [hours, setHours] = useState('');
  const [role, setRole] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [contact, setContact] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (pk) {
      client.get(`/admin/entries/${pk}/update`)
        .then(res => {
          const data = res.data;
          setEvent(data.event);
          setDate(data.date);
          setHours(data.hours);
          setRole(data.role);
          setOrganizer(data.organizer);
          setContact(data.contact);
          setDescription(data.description);
        })
        .catch(err => {
          console.error('Error fetching entry details:', err);
          navigate('/admin/entries'); // Redirect to entries if something goes wrong
        });
    }
  }, [pk, navigate]);

  function validateUpdate() {
    if (!event) return "Entry must have event";
    if (!date) return "Entry must have date";
    if (!hours) return "Entry must have hours";
    if (!role) return "Entry must have role";
    if (!organizer) return "Entry must have organizer";
    if (!contact) return "Entry must have contact";
    if (!description) return "Entry must have description";
    return null;
  }

  function clickUpdate(e) {
    e.preventDefault();

    const validationError = validateUpdate();
    if (validationError) {
      setErrorUpdate(validationError);
      return;
    }

    // Update the entry and set verification to false
    client.post(
      `/admin/entries/${pk}/update`,
      {
        event: event,
        date: date,
        hours: hours,
        role: role,
        organizer: organizer,
        contact: contact,
        description: description,
        verified: false  // Reset verification status after update
      },
      {
        headers: {
          'X-CSRFToken': csrftoken
        }
      }
    ).then(res => {
      alert('Entry updated successfully.');
      navigate('/admin/entries');
    }).catch(err => {
      console.error('Error updating entry:', err);
      if (err.response && err.response.data) {
        setErrorUpdate("Error: " + JSON.stringify(err.response.data));
      } else {
        setErrorUpdate("Error updating entry.");
      }
    });
  }

  const positiveHours = (event) => {
    const hours = parseInt(event.target.value);
    if (hours >= 0) setHours(hours);
  };

  return (
    <>
      <Header />
      <div className="center form-container">
        <h1 className="form-title">Admin Update Event</h1>

        <p style={{ color: 'red' }}>
          WARNING: Updating an entry will result in it being unverified. In order to learn more about how verification works on TimberwolveService, 
          <span 
            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate('/about-verification')}
          >
            {' '}click here
          </span>
        </p>

        <p style={{ color: 'red' }}>You must fill in every input, even if you are not changing it, in order to update the event.</p>
        <Form onSubmit={clickUpdate}>
          <Form.Group className="mb-3" controlId="formBasicEventName">
            <Form.Label>Event Name</Form.Label>
            <Form.Control type="text" placeholder="Update Event" value={event} onChange={e => setEvent(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicDate">
            <Form.Label>Date</Form.Label>
            <Form.Control type="date" placeholder="Update Date" value={date} onChange={e => setDate(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicHours">
            <Form.Label>Hours</Form.Label>
            <Form.Control type="number" placeholder="Update Hours Worked" value={hours} onChange={positiveHours} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicRole">
            <Form.Label>Role</Form.Label>
            <Form.Control type="text" placeholder="Update Role" value={role} onChange={e => setRole(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicOrganizer">
            <Form.Label>Organizer</Form.Label>
            <Form.Control type="text" placeholder="Update Organizer" value={organizer} onChange={e => setOrganizer(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicContact">
            <Form.Label>Contact (Email Used for Verification)</Form.Label>
            <Form.Control type="email" placeholder="Update Contact" value={contact} onChange={e => setContact(e.target.value)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" placeholder="Update Description" value={description} onChange={e => setDescription(e.target.value)} />
          </Form.Group>

          {ErrorUpdate && <p style={{ color: 'red' }}>{ErrorUpdate}</p>}

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
}
